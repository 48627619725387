@import '@shared-styles/src/assets/scss/colors.scss';

.orders_column_filter_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: -10px;

  .column_filter_columns {
    color: $sub-text;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    cursor: pointer;
  }
}
