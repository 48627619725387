@import '@shared-styles/src/assets/scss/colors.scss';

.stepper-wrapper {
  position: relative;

  .stepper-position {
    width: 100%;

    .stepper {
      isolation: isolate;
      justify-content: space-between;

      &::before {
        content: '';
        position: absolute;
        left: 35px;
        top: 10px;
        width: calc(100% - 70px);
        border-bottom: 2px solid $stepper-line;
        z-index: -1;
      }

      .step {
        width: 80px;
        flex: none;

        .MuiStepConnector-horizontal {
          display: none;
        }

        .MuiStepLabel-iconContainer {
          &.Mui-disabled {
            svg {
              color: $stepper-disabled;
            }
          }
        }

        .stepLabel {
          svg {
            outline: 10px solid $phoenix-background;
            border-radius: 50%;
          }

          .MuiStepLabel-iconContainer {
            svg {
              text {
                fill: $white;
              }
            }

            .Mui-completed,
            .Mui-active {
              color: $prim-ui !important;
            }
          }

          .MuiStepLabel-label {
            color: $white;
          }

          &.Mui-disabled {
            svg {
              circle {
                fill: $component-background;
                stroke: $light-border;
              }
            }

            .MuiStepLabel-label {
              opacity: 0.6;
            }
          }
        }
      }
    }
  }
}