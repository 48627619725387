@import '@shared-styles/src/assets/scss/colors.scss';

.nested_row_store_fron_events {
  position: relative;

  .transaction_container {
    height: 100%;
    width: 100%;
    padding: 10px 0;

    .transactions_table_container {
      border-radius: 10px;
      background: $dark-bg-2;
      border: 1px solid rgba(163, 174, 208, 0.2);
      overflow: auto;
      .event_nested_table {
        .table_header {
          .table_header_cell {
            &:first-child,
            &:nth-child(2) {
              position: unset !important;
            }
          }
        }
        .table_body {
          .table_header_cell {
            width: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }
}
