@import '@shared-styles/src/assets/scss/colors.scss';

.common_multi_select_container {
  display: flex;
  flex-direction: column;
  gap: 6px;
  min-height: 1.4375em;

  .MuiAutocomplete-root {
    max-height: 35px;
    &.MuiAutocomplete-hasPopupIcon {
      width: auto !important;
    }
    .MuiInputBase-root {
      input {
        padding: 4px;
      }
      .MuiChip-root {
        height: 28px;
        margin: 1px;
        .MuiChip-label {
          padding: 0 8px;
        }
      }
    }
    .MuiFormControl-root {
      position: sticky;
      z-index: 999;
      .MuiInputBase-root {
        max-height: 70px;
        overflow: auto;
      }
    }
  }

  .text_field_input_multiselect {
    border: none;
    border-radius: 7px;
    background: $component-background;
    @if $dark-theme {
      border: 1px solid $border;
    }

    ::placeholder {
      color: $placeholder;
      opacity: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family:
        work-sans,
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        Roboto,
        sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: -0.28px;
    }

    &:focus-visible {
      outline: none;
    }

    .MuiChip-label {
      font-family: 'work-sans';
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  .MuiOutlinedInput-root {
    padding-left: 6px !important;
    padding-bottom: 2px !important;
    padding-top: 2px !important;
    color: $white;
  }
}
.dropDownBackdrop {
  background: $component-background !important;
}
