@import '@shared-styles/src/assets/scss/colors.scss';
.store_front_events {
  height: 100%;
  display: flex;
  width: 100%;
  gap: 10px;

  @media (max-width: 535px) {
    flex-direction: column-reverse;
  }
  .shopify_wrapper {
    height: 100%;
    display: flex;
    width: 100%;
    overflow: auto;
    
    .store_management {
      width: 100%;
      display: grid;
      grid-template-rows: 1fr min-content;
      gap: 15px;
      position: relative;

      .Common_Table {
        .resolved_header,
        .resolved_row {
          padding: 5px;
          &.resolved {
            background-color: #00ff0066;
          }
          &.not_resolved {
            background-color: #ff000066;
          }
        }
      }
    }
  }
}
