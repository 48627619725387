.image-selector-container {
  display: grid;
  gap: 20px;
  padding-top: 16px;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
}

.MuiBox-root {
  &:has(.product-section-wrapper) {
    width: 100%;
    max-width: 1100px;
    height: fit-content;
    max-height: calc(100vh - 100px);
  }
}