@import '@shared-styles/src/assets/scss/colors.scss';

.subscription-projection-page {
  display: flex;
  gap: 10px;
  .sample_div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: calc(100% - 40px);
    .subscription-projection-cards {
      display: grid;
      gap: 10px;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

      .card {
        display: flex;
        padding: 10px;
        align-items: center;
        gap: 6px;

        border-radius: 15px;
        background: $dark-bg-2;

        .icon_wrapper {
          min-width: 46px;
          min-height: 46px;
          width: 46px;
          height: 46px;
          border-radius: 56px;
          background: $icon-background;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: 26px;
            height: 26px;
          }

          .revenue,
          .cancelled_subscribers {
            path {
              fill: $prim-ui;
            }
          }

          .net_revenue,
          .salvage {
            path {
              fill: $sec-ui;
            }
          }

          .active_subscribers {
            path {
              fill: $success;
            }
          }

          .orders {
            path {
              fill: $orange;
            }
          }
        }

        .content_wrapper {
          display: flex;
          flex-direction: column;
          overflow: hidden;

          .label {
            color: $white;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            letter-spacing: -0.28px;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // white-space: nowrap;
          }

          .sub_text {
            color: $white;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          @media screen and (max-width: 699px) {
            .sub_text {
              font-size: 18px;
              font-weight: 500;
            }
          }

          .helper_text {
            color: $helper-text;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: -0.24px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    .subscription-projection_container {
      height: fit-content;
      display: flex;
      width: 100%;
      gap: 10px;

      .transaction_top_container {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;
        position: relative;

        .transaction_search_container {
          width: 100%;
          height: 75px;
          border-radius: 20px;
          background-color: $dark-bg-2;
          border-radius: 20px;
          display: flex;
          align-items: center;

          .search_container {
            align-items: center;
            margin-left: 20px;
            display: flex;
            gap: 10px;
            border-radius: 22px;
            height: 45px;
            width: 800px;
            background-color: $component-background;

            svg {
              margin-left: 20px;
            }

            .search_input {
              width: 90%;
            }
          }
        }

        .transactions_table_container {
          .transactions_table {
            .table_body {
              .row_collapsed {
                .nested-table-cell {
                  background: $dark-bg;
                  position: sticky;
                  left: 0;
                }

                .table_border_none {
                  border: none !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
