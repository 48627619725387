@import '@shared-styles/src/assets/scss/colors.scss';

.order-filter-selection {
  .filter-selection-form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .transaction_for_submit {
      width: 120px;
      height: 40px;
    }

    .filter_form_input {
      label {
        color: $white !important;
      }
    }

    .direct_sale_box {
      .common_input_label {
        margin-bottom: 3px;
        font-size: 12px;
      }

      .flexContainer {
        display: flex;
        gap: 8px;
      }

      .text_field_wrapper {
        .MuiInputAdornment-root {
          width: 0;
        }
      }
    }

    .flexBox {
      display: flex;
    }

    .spaceBetween {
      justify-content: space-between;
    }

    .alignCenter {
      align-items: center;
    }
    .common_box {
      .common_input_label {
        margin-bottom: 3px;
        font-size: 12px;
      }
      .flexContainer {
        display: flex;
        gap: 8px;
      }
      .duel-datepicker {
        .MuiInputBase-root {
          padding: 12px 5px;
          .MuiInputAdornment-root {
            .MuiButtonBase-root {
              padding: 8px 5px;
            }
          }
          > input {
            font-size: 13px;
          }
        }
      }
    }
  }
}
