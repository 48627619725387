@import '@shared-styles/src/assets/scss/colors.scss';

.onboardingStep1,
.onboardingStep2,
.onboardingStep3 {
  .stepper-section {
    padding: 25px 0 0 0;
    height: 100px;
  }
}

.onboardingStep2 {
  .onboarding-fields {
    max-height: 300px;
    overflow: auto;
  }

  .button-wrapper {
    padding: 25px 0;

    .store-button {
      height: 50px;
      width: 100%;
      margin-bottom: 25px;

      .btn-store {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        border: 1px solid gray;
        background: inherit;
        color: $white;

        &.d-flex {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 15px;
        }

        .icon-Wrapper {
          display: flex;
          gap: 10px;

          svg {
            cursor: pointer;
          }
        }
      }
    }

    .add-button {
      height: 50px;
      width: 100%;
      cursor: pointer;

      .btn-add-store {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        border: 1px dashed gray;
        background: inherit;
        color: $white;
        cursor: pointer;

        &.d-flex {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 15px;
        }
      }
    }
  }
}

.onboardingStep3 {
  .skip-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;

    .skip {
      background-color: inherit;
      color: $white;
      border: none;
      font-family: $default-font;
      cursor: pointer;
      font-size: 14px;
      text-decoration: underline;
    }
  }
}
