@import '../../../assets/scss/colors.scss';

.custom_modal {
  backdrop-filter: blur(2px);
  &.filterPopup {
    height: 100%;

    .popup-section {
      max-width: unset;
    }

    .model-content {
      overflow: auto !important;
    }
  }

  .popup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    .heading {
      color: $white;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 11px;
    }

    .close_icon {
      width: 15px;
      height: 15px;
      color: $white;
      cursor: pointer;
    }
  }

  .content-style {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
    max-height: calc(100vh - 100px);
    min-width: min(600px, calc(100vw - 40px));
    max-width: min(1100px, calc(100vw - 40px));
    border-radius: 20px;
    border: 1px solid $border;
    background-color: $dark-bg-2;
    display: flex;
    flex-direction: column;
    gap: 30px;
    outline: unset;
    box-shadow: 0 10px 40px $box-shadow;

    .model-header {
      color: $white;
    }
  }

  .popup-text {
    color: $white;
  }

  .model-content {
    overflow: auto;

    .popup-section {
      .fields {
        margin-bottom: 10px;
      }
    }
  }

  .model-footer {
    .bottom {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      align-self: stretch;

      .button_1 {
        display: flex;
        min-width: 120px;
        padding: 10px 15px;
        justify-content: center;
        align-items: center;
        gap: 12px;
        border-radius: 10px;
        border: 1px solid $sub-text;
        background: $dark-bg-2;
        box-shadow: -2px 4px 20px 0px rgba(0, 0, 0, 0.1);
      }

      .positive {
        border-radius: 10px;
        font-weight: 600;
        background: $sub-text;
        color: $dark-bg-2;
        box-shadow: -2px 4px 20px 0px rgba(0, 0, 0, 0.1);
      }
    }
  }

  .MuiBackdrop-root {
    background-color: $overlay;
  }
}
