@import '@shared-styles/src/assets/scss/colors.scss';

.userPermissions {
  .filter_form_input {
    margin-bottom: 15px;
  }
  .multiLevelSelect {
    .input-label-permission {
      font-size: 12px;
      color: $white;
      margin-bottom: 2px;
    }
    .dropdown-input {
      display: none;
    }
    .dropdown-list {
      width: 100%;
      background-color: $component-background;
      li {
        color: $white;
        margin: 0;
        &:hover {
          color: $white;
          background-color: $dark-bg-2;
        }
        .nested-submenu {
          background-color: $component-background;
          box-shadow: 6px 6px 50px $box-shadow;
        }
      }
    }
  }
}
.main-submenu {
  left: 100%;
  transform: translateY(-50%);
}
