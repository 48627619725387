@import '@shared-styles/src/assets/scss/colors.scss';

.transaction_container {
  height: 100%;
  display: flex;
  width: 100%;
  gap: 10px;

    @media (max-width: 535px) {
    flex-direction: column-reverse;
  }

  .transaction_top_container {
    height: 100%;
    width: calc(100% - 48px);
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
    overflow: auto;

      @media (max-width: 535px) {
      width: 100%;
    }

    .transaction_search_container {
      width: 100%;
      border-radius: 20px;
      display: flex;
      align-items: center;
      gap: 20px;
      position: relative;
      z-index: 5;
    }

    .transactions_table_container {
      .transactions_table {
        .table_body {
          .row_collapsed {
            .nested-table-cell {
              position: sticky;
              left: 0;
            }

            .table_border_none {
              border: none !important;
            }
          }
        }
        .table_body,
        .table_header {
          tr {
            td,
            th {
              &:first-child {
                position: sticky;
                left: 0;
                &:not(th) {
                  z-index: 2;
                }
                &:not(td) {
                  z-index: 3;
                  padding: 6px 24px;
                }
              }
              &:nth-child(2) {
                position: sticky;
                left: 48px;
                &:not(th) {
                  z-index: 2;
                }
                &:not(td) {
                  z-index: 3;
                }
              }
            }
          }
        }
        .expand-row {
          color: $white;
        }
      }
    }
    .pagination-button {
      color: #fff;
      font-size: 14px;
      padding: 5px 15px;
      background: $dark-bg-2;
      border-radius: 50px;
      line-height: 1.43;
      letter-spacing: 0.01071em;
      text-transform: none;
      border-radius: 8px;
      text-align: center;
      box-sizing: border-box;
      min-width: 32px;
      height: 32px;
      padding: 0 6px;
      margin: 0 3px;
      &active {
        background: 'rose';
      }
    }
    .pagination-button.active {
      background: #f90182 !important;
    }
    .pagination-button.disabled {
      color: grey !important;
      cursor: default;
    }
  }
}
.filterContainer {
  position: absolute;
  border: 1px solid $table-border;
  left: 15px;
  top: calc(100% + 5px);
  box-shadow: -2px 6px 20px $box-shadow;
  z-index: 1;
  border-radius: 10px;
  background-color: $dark-bg-2;

  .filterFields {
    display: grid;
    grid-template-rows: min-content 1fr;
    gap: 2px;
    padding: 10px 0;
    .search-text {
      color: $helper-text;
      font-size: 12px;
      font-weight: 500;
      padding: 0 16px;
    }
    .filterList {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      li {
        padding: 5px 16px;
        font-weight: 500;
        font-size: 14px;
        &:hover {
          background-color: $table-hover;
        }
      }
    }
  }
}
