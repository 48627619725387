.quill-text {
  h1,
  h2,
  h3 {
    font-size: revert-layer !important;
    font-weight: revert-layer !important;
  }
  ol {
    list-style: revert;
    list-style-position: inside;
  }
  ul {
    list-style: revert;
    list-style-position: inside;
  }
  .ql-align-center {
    text-align: center !important;
  }
  .ql-align-justify {
    text-align: justify !important;
  }
  .ql-align-right {
    text-align: right !important;
  }
  .ql-align-left {
    text-align: left !important;
  }
}
