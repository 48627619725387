.tab-content {
  height: 100%;
  display: grid;
  grid-template-rows: min-content 1fr;
  overflow: auto;
  gap: 10px;

  .tab-content-wrapper {
    overflow: auto;
  }
}
