@import '@shared-styles/src/assets/scss/colors.scss';
.projection-action-selection {
  padding-right: 10px;

  .subscription-filter-selection-form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .subscription_for_submit {
      width: 120px;
      height: 40px;
    }
    .clear_button {
      padding: 0px !important;
    }

    .filter_form_input {
      label {
        color: $white;
        font-family: 'work-sans, -apple-system, BlinkMacSystemFont, ' Segoe UI
          ', Roboto';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
      }
    }
    .common_box {
      .common_input_label {
        margin-bottom: 3px;
        font-size: 12px;
      }
      .flexContainer {
        display: flex;
        gap: 8px;
      }
      .duel-datepicker {
        .MuiInputBase-root {
          padding: 12px 5px;
          .MuiInputAdornment-root {
            .MuiButtonBase-root {
              padding: 8px 5px;
            }
          }
          > input {
            font-size: 13px;
          }
        }
      }
    }
    .subscriptionAmount {
      .MuiInputAdornment-root {
        width: 0;
      }
    }
  }
}
