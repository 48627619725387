.loading-scroll-bar {
  &::after {
    content: 'Loading...';
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    inset: 0;
    backdrop-filter: blur(5px);
  }
}
