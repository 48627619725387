@import '@shared-styles/src/assets/scss/colors.scss';

.store-front-event-selection {
  .filter-selection-form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .common_box {
      .common_input_label {
        margin-bottom: 3px;
        font-size: 12px;
      }
      .flexContainer {
        display: flex;
        gap: 8px;
      }
      .duel-datepicker {
        .MuiInputBase-root {
          padding: 12px 5px;
          .MuiInputAdornment-root {
            .MuiButtonBase-root {
              padding: 8px 5px;
            }
          }
          > input {
            font-size: 13px;
          }
        }
      }
    }

    .transaction_for_submit {
      width: 120px;
      height: 40px;
    }
    .clear_button {
      padding: 0px !important;
    }
    .filter_form_input {
      label {
        color: $white !important;
      }
    }
  }
}
