@import '@shared-styles/src/assets/scss/colors.scss';

.loginContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  position: relative;
  padding: 20px;

  .title_login_container {
    display: flex;
    align-items: center;
    gap: 10px;

    .title_text {
      font-size: 30px;
    }
  }
  form {
    width: 100%;
  }
  .login_form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    max-width: 400px;
    gap: 5px;
    margin: auto;
    position: relative;
    z-index: 1;

    .form_text {
      display: flex;
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
    }
    .login_input {
      .text_field_input {
        border-radius: 8px;
        height: auto;
        input {
          padding: 12px 15px;
          caret-color: $white;
          color: $white;
        }
      }
    }

    ::placeholder {
      color: #776b72;
      opacity: 1;
      /* Firefox */
    }
  }

  .animated-circle {
    width: 484px;
    height: 484px;
    border-radius: 50%;
    position: absolute;
    filter: blur(100px);
  }

  .right-circle {
    top: -124px;
    right: -100px;
    opacity: 0.4;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(103, 80, 164, 0.98) 0%,
      $box-shadow 100%
    );
    background-blend-mode: screen;
  }

  .top-circle {
    top: -215px;
    left: -150px;
    opacity: 0.4;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(255, 216, 175, 0.98) 0%,
      $box-shadow 100%
    );
    background-blend-mode: screen;
  }

  .bottom-circle {
    bottom: -350px;
    left: 190px;
    opacity: 0.4;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(200, 114, 202, 0.98) 0%,
      $box-shadow 100%
    );
    background-blend-mode: screen;
  }
}
