@import '@shared-styles/src/assets/scss/colors.scss';

.order-details-page {
  display: grid;
  grid-template-columns: 1fr min-content;
  height: fit-content;
  width: fit-content;
  margin-inline: auto;
  gap: 10px;
  position: relative;
  max-width: 1100px;

  .left-container {
    height: fit-content;
    display: flex;
    gap: 10px;
    width: 100%;
    overflow: auto;
    margin-inline: auto;

    .order-details-container {
      width: calc(100% - 350px);
      display: flex;
      flex-direction: column;
      gap: 15px;
      height: fit-content;

      .products-container {
        min-height: fit-content;
        max-height: 450px;
        overflow: auto;
        border: 1px solid $border;
        border-radius: 15px;
        display: flex;
        background: $dark-bg-2;
        padding: 0 15px 15px 15px;
        flex-direction: column;

        .product-header {
          display: flex;
          width: 100%;
          position: sticky;
          top: 0;
          padding: 15px 0px 10px 0px;
          justify-content: space-between;

          .track-id {
            color: $sec-ui;
            text-decoration: underline;
          }
        }

        .products {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;

          .item {
            display: flex;
            width: 100%;
            justify-content: space-between;

            .product-wrap {
              display: flex;
              align-items: self-start;
              justify-content: center;
              gap: 10px;

              .product-image {
                height: 80px;
                width: 80px;
                border-radius: 10px;
              }

              .item-content {
                padding: 5px 0;
                font-size: 14px;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: baseline;
                gap: 2px;

                .qty-wrap {
                  color: $sub-text;
                  display: flex;
                  align-items: center;
                }

                .price-varient {
                  display: flex;
                  align-items: center;
                  gap: 15px;

                  > span {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                  }
                }

                .offer {
                  color: $sub-text;
                }

                .vip-price {
                  color: $sec-ui;
                  text-decoration: underline;
                }

                .final-amt {
                  color: $success;
                }

                .item-name {
                  font-size: 18px;
                }
                .refund-badge {
                  background: #2b539e;
                  color: #ffffff;
                  border: 1px solid var(--placeholder); 
                  padding: 3px 12px;
                  border-radius: 8px;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 500;
                  text-transform: capitalize;
                  width: -moz-fit-content;
                  width: fit-content;
                  text-align: center;
                }
              }
            }
            .discount-amount {
              color: $sub-text;
              text-decoration: line-through;
            }
          }

          .divider {
            border-style: none;
            border-bottom: 1px solid $border;
            margin: 10px 0px 10px 0px;
            width: 100%;
          }
        }
      }

      .summary-content {
        border: 1px solid $border;
        border-radius: 15px;
        padding: 15px;
        background: $dark-bg-2;

        .summary-container {
          // padding: 15px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;

          .sum-content {
            width: 100%;

            .discount_savings {
              span {
                color: $success;
              }
            }

            .total {
              border-bottom: 1px solid $border;
              border-top: 1px solid $border;
              font-size: 20px;
              font-weight: 500;
            }

            p {
              display: flex;
              width: 100%;
              justify-content: space-between;
              gap: 5px;
              padding: 8px 0;
              font-size: 16px;
              color: $sub-text;

              span {
                color: $white;
              }
            }
          }
        }

        .saved-amt {
          display: flex;
          margin-top: 10px;
          // padding: 0 15px;
          color: $sub-text;

          span {
            color: $success;
          }
        }
      }

      .notes-customer {
        position: relative;
      }
    }

    .customer-details-container {
      width: 350px;

      .customer {
        margin: 0 0 15px 0;
        background: $dark-bg-2;
        border-radius: 15px;
        border: 1px solid $border;
        padding: 15px;
        display: flex;
        gap: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: baseline;

        .cus-body {
          color: $sub-text;
          display: flex;
          flex-direction: column;
          gap: 9px;
          font-size: 14px;
          align-items: baseline;

          .name-wrap {
            color: $sec-ui;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        .cus-title {
          font-size: 14px;
        }
      }

      .status {
        .history-content {
          padding: 15px;

          .history-wrap {
            text-align: start;
            display: grid;
            color: $sub-text;
            grid-template-columns: 150px 1fr;

            .time-wrapper {
              display: flex;
              justify-content: space-between;
              border-right: 0.2px solid rgba($sub-text, 0.5);
              padding: 10px;
              position: relative;

              &::after {
                content: '';
                background-color: $sub-text;
                border: 5px solid #485063;
                border-radius: 50%;
                padding-left: 9px;
                height: 9px;
                position: absolute;
                right: 0;
                transform: translateX(50%);
              }
            }

            .history-wrapper {
              display: flex;
              flex-direction: column;
              padding: 10px 25px;
              word-break: break-word;

              .heading-wrap {
                font-size: 18px;
                color: $white;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 860px) {
    width: 100%;
    .left-container {
      flex-direction: column;
      .customer-details-container,
      .order-details-container {
        width: 100%;
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}
