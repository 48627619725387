@import '@shared-styles/src/assets/scss/colors.scss';
.user_permission {
  .shopify_wrapper {
    max-height: 600px;
    display: flex;
    width: 100%;
    .store_management {
      width: 100%;
      display: grid;
      grid-template-rows: 1fr min-content;
      position: relative;
      .Common_Table {
        .MuiTable-root {
          min-height: 250px;
        }
        border-radius: none !important;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        .risk_management_table {
          .table_header {
            .MuiTableRow-head {
              .table_header_cell {
                .MuiTableCell-stickyHeader {
                  background-color: rgba(163, 174, 208, 0.2) !important;
                }
              }
              .MuiTableRow-root {
                height: 48px;
                padding: 0px 12px;
                border-bottom: 1px solid rgba(163, 174, 208, 0.1);
                background-color: $dark-bg-2 !important;

                &.header_text {
                  color: $sub-text;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 600;
                  white-space: nowrap;
                }
              }
            }

            .flex-row {
              display: flex;
              align-items: center;
            }
          }

          .table_body {
            .table_row {
              .table_cell {
                height: 64px;
                padding: 0px 12px;
                white-space: nowrap;
                color: $white;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                border-bottom: 1px solid rgba(163, 174, 208, 0.1);
                .action_permission {
                  background-color: transparent;
                  display: flex;
                  flex-direction: row;
                  gap: 10px;
                }
                .contant {
                  display: flex;
                  flex-direction: row;

                  .value {
                    font-size: 14px;
                    font-weight: 500;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    &.permission {
                      max-width: 170px;

                      p {
                        background-color: $placeholder;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 10px;
                        padding: 5px 7px;
                        border-radius: 50px;
                      }
                    }

                    &.flex-box {
                      display: flex;
                      gap: 10px;
                    }
                  }
                }
              }

              .link_btn {
                cursor: pointer;
                color: $sec-ui;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;

                &:hover {
                  text-decoration-line: underline;
                }
              }
            }
          }
        }
      }
    }
  }
  .add_store {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $component-background;
    padding: 5px;
  }
}
