@import '@shared-styles/src/assets/scss/colors.scss';

.actives {
  background-color: $dark-bg !important;
}

.button_group {
  border: 1px solid $placeholder;
  border-radius: 10px;
  background: $dark-bg-2;
  display: inline-flex;
  flex-direction: row;
  overflow: hidden;

  .custom_button {
    display: flex;
    padding: 12px 15px;
    align-items: center;
    gap: 5px;
    text-transform: none;
    color: $white;
    font-family: 'work-sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    /* 100% */
    letter-spacing: -0.28px;
    border: none;
    border-right: 1px solid $placeholder;
    background: $dark-bg-2;
    min-width: 60px;
    cursor: pointer;
    justify-content: center;

    &:last-child {
      border-right: none;
    }
  }
}
