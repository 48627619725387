@import '@shared-styles/src/assets/scss/colors.scss';
.proccessor {
  .h-full {
    .Common_Table {
      .table {
        .table_body {
          .table_cell {
            .icons {
              display: flex;
              gap: 10px;
              .create {
                width: 20px;
                height: 20px;
              }
              .delete {
                width: 22px;
                height: 22px;
              }
            }
          }
        }
      }
    }
  }
}
