@import '@shared-styles/src/assets/scss/colors.scss';

.multiLevelSelect {
  .input-label-permission {
    font-size: 12px;
    color: $white;
    margin-bottom: 2px;
  }
  .dropdown-input {
    display: none;
  }
  .dropdown-list {
    width: 100%;
    background-color: $component-background;
    li {
      color: $white;
      margin: 0;
      &:hover {
        color: $white;
        background-color: $dark-bg-2;
      }
      .nested-submenu {
        background-color: $component-background;
        box-shadow: 6px 6px 50px $box-shadow;
      }
    }
  }
}

.custom_modal .model-content {
  overflow: unset;
}

.admin_top_container {
  height: 100%;
  display: flex;
  width: 100%;
  gap: 10px;

  @media (max-width: 535px) {
    flex-direction: column-reverse;
  }

  .admin_left_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
    overflow: auto;
    .admin_search_container {
      position: relative;
      z-index: 5;
    }
  }
}
