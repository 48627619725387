@import '@shared-styles/src/assets/scss/colors.scss';

.new_dashboard {
  position: relative;
  display: grid;
  gap: 10px;

  .statistics_cards {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)) !important;

    @media screen and (max-width: 1600px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 1080px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 600px) {
      grid-template-columns: repeat(1, 1fr);
    }

    .card-wrapper {
      padding: 10px;
      border: 1px solid $border;
      border-radius: 15px;

      .date {
        padding-bottom: 5px;
        color: $white;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
      }

      .cards {
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

        .card {
          display: flex;
          padding: 10px;
          align-items: center;
          gap: 6px;

          border-radius: 15px;
          background: $dark-bg-2;

          .icon_wrapper {
            min-width: 46px;
            min-height: 46px;
            width: 46px;
            height: 46px;
            border-radius: 56px;
            background: $icon-background;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              width: 26px;
              height: 26px;
            }

            .revenue,
            .cancelled_subscribers {
              path {
                fill: $prim-ui;
              }
            }

            .refund {
              path {
                fill: $yellow;
              }
            }

            .net_revenue,
            .salvage {
              path {
                fill: $sec-ui;
              }
            }

            .active_subscribers {
              path {
                fill: $success;
              }
            }

            .orders {
              path {
                fill: $orange;
              }
            }
          }

          .content_wrapper {
            display: flex;
            flex-direction: column;
            overflow: hidden;

            .label {
              color: $white;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              letter-spacing: -0.28px;
              // overflow: hidden;
              // text-overflow: ellipsis;
              // white-space: nowrap;
            }

            .sub_text {
              color: $white;
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin: auto;
            }

            @media screen and (max-width: 699px) {
              .sub_text {
                font-size: 18px;
                font-weight: 500;
              }
            }

            .helper_text {
              color: $helper-text;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              letter-spacing: -0.24px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  .dashboard_filter {
    display: flex;
    padding: 15px;
    justify-content: start;
    align-items: flex-start;
    gap: 25px;
    border-radius: 20px;
    background: $dark-bg-2;
    border: 1px solid $light-border;

    .portal_id {
      width: 250px;

      input {
        box-sizing: border-box !important;
        padding: 18px !important;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;

      .dual-date-container {
        max-width: 100%;
      }
    }

  }

  .line_chart_container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .lg_card_container {
      justify-content: space-between;
      border-radius: 20px;
      background: $dark-bg-2;
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      border: 1px solid $light-border;

      .no_data {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 16px;
        color: $sub-text;
      }

      .header {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        min-height: 38px;
        height: fit-content;

        .header_text {
          color: $white;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
        }
      }

      .sub_header {
        display: grid;

        .sub_header_1 {
          font-size: 14px;
          font-weight: 500;
          line-height: 16.42px;
          letter-spacing: -0.02em;
          text-align: left;
          color: #a3aed0;
        }

        .sub_header_2 {
          font-size: 15px;
          font-weight: 600;
          line-height: 18.77px;
          letter-spacing: -0.02em;
          text-align: left;
          color: #ffffff;
        }
      }

      .footer {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        gap: 10px;

        @media (max-width: 453px) {
          flex-wrap: wrap;
          justify-content: flex-start;
        }

        .footer_details {
          display: flex;
          align-items: center;
          justify-items: center;
          flex-direction: row;
          gap: 5px;

          .indicator {
            border-radius: 15px;
            width: 12px;
            height: 12px;
          }

          .footer_text {
            color: $sub-text;
            font-size: 12px;
            font-weight: 500;
            line-height: 14.08px;
            letter-spacing: -0.02em;
            text-align: left;
          }
        }

        .transaction_values {
          text-align: center;
          font-size: 14px;
        }
      }

      .body {
        display: flex;
        height: 100%;
        gap: 20px;

        .left_side_details {
          display: flex;
          flex-direction: row;
          gap: 20px;

          .stats_details {
            display: flex;
            gap: 22px;
            flex-direction: column;

            .head_text {
              color: $helper-text;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              white-space: nowrap;
              display: flex;
              gap: 8px;
              white-space: nowrap;
              align-items: center;

              .indicator {
                border-radius: 15px;
                width: 12px;
                height: 12px;
              }
            }

            .sub_text {
              color: $white;
              font-size: 21px;
              font-style: normal;
              font-weight: 600;
              white-space: nowrap;
              display: flex;
              justify-content: flex-start;
            }
          }
        }

        @media (max-width: 560px) {
          flex-direction: column;

          .left_side_details {
            .stats_details {
              flex-direction: row;
              gap: 10px 25px;
              flex-wrap: wrap;
            }
          }
        }
      }

      .chart_wrapper {
        width: 100%;
        overflow: hidden;
        height: 100%;
        max-height: 250px;

        .chart {
          min-height: 250px;
        }
      }

      .approval_chat {
        max-height: 350px;
      }

      &:has(.chart_wrapper .no_data) {
        .header {
          min-height: max-content;
        }
      }
    }
  }

  .md_card_container {
    border-radius: 20px;
    background: $dark-bg-2;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    border: 1px solid $light-border;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 30px;

      .header_text {
        color: $white;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: -0.32px;
      }
    }

    .chart_wrapper {
      width: 100%;
      overflow: hidden;

      .chart {
        min-height: 200px;
      }
    }

    .chargeback_summary {
      display: flex;
      padding: 12px;
      flex-direction: column;
      align-items: flex-start;
      gap: 6.5px;
      border-radius: 15px;
      background: $phoenix-background;

      .content_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        .label {
          color: $sub-text;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          letter-spacing: -0.28px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .value {
          color: $white;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          letter-spacing: -0.28px;
        }
      }

      .divider {
        width: 100%;
        stroke-width: 1px;
        stroke: $sub-text;
        opacity: 0.5;
      }

      .faded {
        opacity: 0.1;
      }
    }
  }
}

.cycle-tooltip {
  .MuiTooltip-tooltip {
    margin-top: 6px !important;
    border: unset !important;
    width: 250px !important;
    padding: 10px 20px 10px 20px;
    border-radius: 20px;
    box-shadow: $tooltip-shadow;
    font-family: 'work-sans';

    .divider {
      border-bottom: 1px solid $divider;
    }

    .cycle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 0px 5px 0px;

      .title {
        font-size: 14px;
        color: $white;
        font-weight: 500;
      }
    }
  }
}

.month-data-container {
  padding: 10px;
  border: 1px solid var(--border);
  border-radius: 15px;
  display: grid;
  gap: 10px;

  .month-date-picker {
    .MuiFormControl-root {
      margin-top: 5px;

      .MuiInputBase-root {
        background-color: $dark-bg-2;
        border-radius: 8px;
        border: 1px solid $border;
      }

      input {
        color: var(--white);
        padding: 9px 0 9px 16px;
      }

      fieldset {
        display: none;
      }
    }
  }
}

.MuiPickersPopper-root {
  margin-top: 5px !important;

  .MuiPickersPopper-paper {
    background: none;
    background-color: $dark-bg-2;

    .Mui-selected,
    .Mui-selected:is(:hover, :focus) {
      background-color: $prim-ui;
      color: $alwaysWhite;
    }
  }
}