.puck-editor {
  ._PuckLayout_1g88c_31 {
    ._PuckLayout-rightSideBar_1g88c_142 {
      ._PuckFields_jp3lw_1 {
        ._ArrayField_1go19_5 {
          ._ArrayFieldItem_1go19_63 {
            ._ArrayFieldItem-summary_1go19_97 {
              ._IconButton_swpni_1 {
                .lucide {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
