@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-page-builder-theme: 79 70 229;
  }
}

body {
  margin: 0;
  font-family:
    work-sans,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: work-sans;
  src: url('@shared-styles/src/assets/fonts/WorkSans-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: work-sans;
  src: url('@shared-styles/src/assets/fonts/WorkSans-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: work-sans;
  src: url('@shared-styles/src/assets/fonts/WorkSans-Bold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: work-sans;
  src: url('@shared-styles/src/assets/fonts/WorkSans-Black.ttf');
  font-weight: 800;
}

@font-face {
  font-family: roboto;
  src: url('@shared-styles/src/assets/fonts/Roboto-Medium.ttf');
  font-weight: 600;
}

@font-face {
  font-family: times-new-roman;
  src: url('@shared-styles/src/assets/fonts/OPTITimes-Roman.otf');
  font-weight: 600;
}

@font-face {
  font-family: baskerville;
  src: url('@shared-styles/src/assets/fonts/LibreBaskerville-Regular.ttf');
  font-weight: 600;
}

@font-face {
  font-family: merriweather;
  src: url('@shared-styles/src/assets/fonts/Merriweather-Regular.ttf');
  font-weight: 600;
}

@font-face {
  font-family: pacifico;
  src: url('@shared-styles/src/assets/fonts/Pacifico-Regular.ttf');
  font-weight: 600;
}

@font-face {
  font-family: lobster;
  src: url('@shared-styles/src/assets/fonts/LobsterTwo-Regular.ttf');
  font-weight: 600;
}

@font-face {
  font-family: inter;
  src: url('@shared-styles/src/assets/fonts/Inter-Regular.ttf');
  font-weight: 600;
}
