@import '@shared-styles/src/assets/scss/colors.scss';

.notes-wrapper {
  position: relative;
  display: grid;
  grid-template-rows: min-content 1fr;
  height: 100%;
  overflow: auto;
  gap: 10px;
  .addnotes-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    textarea {
      &:focus {
        box-shadow: none !important;
      }
    }
    .addnotes-box {
      width: 100%;
      height: 100px;
      background-color: $component-background;
      border: 1px solid $light-border;
      font-family:
        work-sans,
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        Roboto;
      border-radius: 7px;
      padding: 12px 16px;
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
      color: $white;
      margin-left: 0;
      resize: vertical;
    }

    .addnotes-box:focus-visible {
      outline: none;
    }

    .addnotes-button {
      padding: 10px 15px;
      min-width: 120px;
      height: 44px;
      color: $white;
      font-weight: 500;
      cursor: pointer;
      border: 1px solid rgba(163, 174, 208, 0.4);
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      background: $dark-bg-2;
    }

    .addnotes-box::placeholder {
      overflow: hidden;
      color: $placeholder;
      text-overflow: ellipsis;
      font-family:
        work-sans,
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: -0.28px;
    }
  }

  .notes-header {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: flex-end;
    border-radius: 20px;
    background: var(--dark-light, #141e2d);
    gap: 20px;
    padding: 14px 25px;
    margin-top: 20px;
    margin-bottom: 20px;

    .add-notes {
      display: flex;
      min-width: 120px;
      padding: 10px 15px;
      justify-content: center;
      align-items: center;
      gap: 12px;
      border-radius: 10px;
      cursor: pointer;
      border: 1px solid rgba(255, 255, 255, 0.2);
      background: $dark-bg-2;
      box-shadow: -2px 4px 20px 0px rgba(0, 0, 0, 0.1);
    }

    .common-action {
      display: flex;
      cursor: pointer;
      min-width: 120px;
      padding: 10px 15px;
      justify-content: center;
      align-items: center;
      gap: 12px;
      border-radius: 10px;
      background: var(--dark-bg, #0b111a);
      box-shadow: -2px 4px 20px 0px rgba(0, 0, 0, 0.1);
    }
  }

  .notes-body {
    font-size: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: auto;

    .notes-wrap {
      padding: 10px 15px;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      display: flex;
      border-radius: 10px;
      border: 1px solid $placeholder;
      background: $dark-bg-2;

      .title {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;

        .notes-title {
          display: flex;
          align-items: center;
          color: $white;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
        }

        .created-at-date {
          display: flex;
          gap: 2px;
          align-items: center;
          margin-left: 5px;
          color: var(--sub-text, #a3aed0);
          font-size: 12px;
          font-style: italic;
          font-weight: 500;
          line-height: 18px;
        }

        .menu-button {
          background: none;
          border: 0;
          color: white;
          cursor: pointer;
        }
      }

      .notes-para {
        color: $sub-text;
        font-size: 14px;
        text-align: justify;
        padding: 0;
        font-style: normal;
        font-family: 'work-sans';
        font-weight: 500;
        line-height: 18px;
        background-color: transparent;
        border: none;
        width: 100%;
        resize: none;
        border-radius: 2px;
        outline: none;

        &.text-notes-para.focused {
          outline: 1px solid $background1;
          outline-offset: 5px;
        }
      }
    }
  }
}
