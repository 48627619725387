@import '@shared-styles/src/assets/scss/colors.scss';
.payment-routing {
  .h-full {
    .Common_Table {
      .table-cell {
        .input_edit {
          width: 300px;
          height: 42px;
          padding: 12px 16px 12px 16px;
          border-radius: 10px;
          background-color: #212836;
          border: none;
        }
        input:focus {
          outline: none;
          border: none;
        }
      }
    }
  }
  .transaction_for_submit {
    float: right;
    margin: 10px;
    width: 120px;
    background: var(--dark-bg-2, #141e2d);
    height: 40px;
  }
}
.popup-section-paymentRouting {
  display: flex;
  gap: 10px;
  flex-direction: column;

  .popup-question {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: $white;
  }
  .popup-display-common {
    display: flex;
    gap: 5px;
    flex-direction: column;

    .popup-display {
      color: $sub-text;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.02em;
      text-align: left;
    }
  }
}
