@import '@shared-styles/src/assets/scss/colors.scss';

.Dual-Datepicker-Container {
  margin-top: 5px;
  min-width: 255px;

  @if $dark-theme {
    >div:first-child {
      border-radius: 8px;
      border: 1px solid $border;
    }
  }

  input {
    background-color: $component-background;
    color: $white;
    border: none;
    outline: none;

    &~div {
      top: calc(100% + 2px);

      >div {
        background-color: $dark-bg-2;
        border-color: $table-border;
        box-shadow: 0 0 60px $box-shadow;

        &:nth-child(2) {
          padding: 0;
          max-height: 70vh;
          overflow: auto;

          &>div {
            &:first-child {
              padding: 0;

              &>div {
                &:first-child {
                  padding: 10px;
                  border-color: $table-border;

                  li {
                    color: $white;

                    &:hover {
                      color: $phoenix-background;
                      background-color: $sub-text;
                    }
                  }
                }

                &:nth-child(2) {
                  padding: 10px;

                  &>div {

                    &:first-child,
                    &:nth-child(3) {
                      >div {
                        &:first-child {
                          border-color: $table-border;
                          color: $white;

                          button {
                            color: $white;
                          }
                        }

                        &:nth-child(2) {
                          &>div {
                            &:first-child {
                              border: none;
                            }

                            &:nth-child(2) {
                              button {
                                color: $white;

                                &:disabled {
                                  color: $background1;
                                  text-decoration: none !important;
                                }

                                &.text-gray-400 {
                                  color: $table-border;
                                }

                                &.text-blue-500 {
                                  color: $prim-ui;
                                  font-weight: 500;
                                }

                                &.bg-blue-500 {
                                  background-color: $prim-ui;
                                }

                                &.bg-blue-100 {
                                  background-color: $table-border;
                                }
                              }
                            }
                          }
                        }
                      }
                    }

                    &:nth-child(2) {
                      opacity: 0;
                    }
                  }

                  @media (max-width: 940px) {
                    flex-direction: column;
                  }
                }
              }

              @media (max-width: 1570px) {
                flex-direction: column;

                >div {
                  &:first-child {
                    ul {
                      display: grid;
                      grid-template-columns: repeat(auto-fit,
                          minmax(200px, 1fr));

                      li {
                        width: 100%;
                      }
                    }
                  }
                }
              }
            }

            &:nth-child(2) {
              padding: 10px;
              border-color: $table-border;

              button {
                border: none;
                background-color: transparent;
                color: $white;

                &:last-child {
                  background-color: $sub-text;
                  color: $phoenix-background;
                }
              }
            }
          }
        }
      }
    }
  }
}

.dual-date-container {
  width: 100%;
  max-width: 260px;
}