@import '@shared-styles/src/assets/scss/colors.scss';

.app_layout {
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: auto;
  display: grid;
  grid-template-columns: min-content 1fr;

  &.app_shrink {
    width: calc(100vw - 290px);
  }

  .side_navigation_close {
    width: 70px !important;
  }

  .side_navigation {
    transition: all 0.5s ease;
    background: $dark-bg-2;
    width: 240px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-shadow: 5px 0 20px $box-shadow;
    z-index: 12;

    .side_nav_header {
      padding: 10px 10px 10px 16px;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $border;

      svg {
        height: 40px;
        min-width: 40px;
        width: 40px;
      }

      .side_nav_logo {
        width: 115px;
        height: 42px;
        min-width: 115px;
      }

      .arrow_icon {
        width: auto;
        min-width: auto;
        height: auto;
      }
    }

    .side_nav_body {
      padding: 20px 0px 20px 25px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      .filter_values {
        padding-right: 15px;
        .common_multi_select_container {
          .MuiAutocomplete-root {
            .MuiFormControl-root {
              background: linear-gradient(
                100.7deg,
                #f0b351 0%,
                #f0b351 10.43%,
                #ff5acd 100%,
                #6750a4 100%
              );
              .MuiInputBase-root {
                input:focus {
                  outline: none;
                  border: none;
                  box-shadow: none;
                }

                ::placeholder {
                  color: black;
                }
                .MuiButtonBase-root {
                  svg {
                    path {
                      fill: black;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .nav_item {
        color: $sub-text;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        width: 100%;
        min-width: 20px;
        display: flex;
        align-items: center;
        text-decoration: none;
        gap: 10px;
        padding: 1px 0;
        cursor: pointer;

        svg {
          min-width: 20px;
          width: 20px;
        }
      }

      .navLink-subLists {
        position: relative;

        .navList-Subitems-icon {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          z-index: 1;
          color: $sub-text;
          cursor: pointer;
        }
      }
      .nav-sublist {
        li {
          .nav_item {
            font-size: 14px;
            position: relative;
            white-space: nowrap;
            &::before {
              content: '';
              width: 7px;
              height: 7px;
              border-radius: 50%;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: -20px;
              background-color: $sub-text;
            }
            &.active_item {
              &::before {
                background-color: $prim-ui;
              }
            }
          }
        }
      }

      .active_item {
        color: $prim-ui;
        border-right: 4px solid $prim-ui;

        .nav_item_icon {
          path {
            fill: $prim-ui;
          }
        }
      }

      .nav_item_wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        user-select: none;
      }

      .accordion {
        display: grid;
        gap: 5px;
        padding-left: 40px;
        height: 100%;
        overflow: hidden;

        .accordion {
          padding-left: 20px;
        }

        li {
          color: $sub-text;
        }
      }

      .nav-child-wrapper {
        display: grid;
        grid-template-rows: 1fr;
        transition: all 0.25s ease;
        padding-top: 5px;
        .nav-child-wrapper {
          padding-top: 0;
        }
        &.hidden-submenu {
          padding-top: 0px;
          grid-template-rows: 0fr;
        }
      }
    }
  }

  .sidenavOverlay {
    position: fixed;
    inset: 0;
    background-color: #00000011;
    z-index: 11;
    @media (min-width: 1080px) {
      display: none;
    }
  }

  .right_container {
    width: 100%;
    transition: all 0.5s ease;
    display: grid;
    grid-template-rows: min-content 1fr;
    overflow: auto;
    isolation: isolate;

    .top_navigation {
      display: grid;
      grid-template-columns: max-content 1.1fr max-content;
      gap: 30px;
      align-items: center;
      padding: 10px 20px;
      z-index: 10;
      position: relative;

      .header_text {
        display: flex;
        align-items: center;
        color: $white;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: -0.68px;

        .back_button {
          padding-right: 10px;
          cursor: pointer;
          width: 28px;
        }
      }

      .filter_values {
        width: fit-content;
        margin-inline: auto;
        min-width: 400px;

        @media (max-width: 490px) {
          min-width: 290px;
        }
        .inputWrapper {
          border-radius: 25px;
          overflow: hidden;
          input {
            min-width: 50px;
          }
        }

        .common_multi_select_container {
          min-width: max(min(450px, 100%), 300px);
          width: fit-content;
          margin: auto;
        }
        .MuiFormControl-root {
          border: 1px solid $border;
          border-radius: 20px;
          .MuiInputBase-root {
            padding-right: 30px;
            &::before {
              content: '';
              width: 25px;
              height: 25px;
              background-image: url('../assets/icons/search.svg');
              background-size: cover;
              background-repeat: no-repeat;
              margin-right: 4px;
            }
          }
          .MuiAutocomplete-tag {
            &:only-of-type {
              background-color: $table-hover;
              border-radius: 50px;
              min-height: 28px;
              display: inline-flex;
              align-items: center;
              padding: 0 8px;
              cursor: pointer;
            }
          }
          .MuiAutocomplete-endAdornment {
            .MuiAutocomplete-popupIndicator {
              display: none;
            }
            .MuiButtonBase-root {
              path,
              svg {
                fill: $white;
              }
            }
          }
        }
      }
      .right_side_header {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        .timezone-select-container {
          width: 200px;
          &:empty {
            width: 0;
          }
          .MuiInputBase-root {
            padding-right: 40px;
          }
          .MuiInputBase-input {
            padding: 6px 5px;
            font-size: 14px;
          }
        }

        .header-actions {
          display: flex;
          align-items: center;
          gap: 5px;
          padding: 6px 6px 6px 12px;
          border-radius: 50px;
          background-color: $dark-bg-2;

          .logout_image {
            height: 25px;
            min-width: 25px;
            cursor: pointer;
            margin-left: 4px;
          }

          .notification-link {
            cursor: pointer;
            display: flex;
            position: relative;

            &.active_item {
              path {
                fill: $prim-ui;
              }
            }

            &.active-badge {
              &::after {
                content: '';
                width: 6px;
                height: 6px;
                background-color: $error-red;
                position: absolute;
                border-radius: 50%;
                top: 3px;
                right: 3px;
              }
            }
          }
        }
      }
      .moreHeaderMenu {
        display: none;
      }
      @media (max-width: 1080px) {
        grid-template-columns: max-content 1fr;
        gap: 10px;
        padding: 10px 15px;
        .filter_values {
          grid-column: 1/-1;
          grid-row: 2/3;
          .common_multi_select_container {
            min-width: 100%;
          }
        }
        .right_side_header {
          .timezone-select-container {
            .MuiInputBase-root {
              padding-right: 20px;
            }
          }
        }
      }
      @media (max-width: 760px) {
        grid-template-rows: min-content 0fr min-content;
        transition: all 0.25s ease;
        gap: 5px;
        .moreHeaderMenu {
          display: flex;
          width: fit-content;
          margin-left: auto;
          cursor: pointer;
        }
        .filter_values {
          grid-row: 3/4;
        }
        .right_side_header {
          grid-column: 1/-1;
          overflow: hidden;
          height: 100%;
          transition: all 0.25s ease;
          padding-bottom: 0;
        }
        &.showHeaderMenu {
          gap: 10px;
          grid-template-rows: min-content 1fr min-content;
          .right_side_header {
            padding-bottom: 2px;
          }
        }
      }
    }

    .app_container {
      position: relative;
      height: 100%;
      padding: 0px 20px 15px;
      overflow: auto;
      @media (max-width: 1080px) {
        padding: 0 15px 15px;
      }
    }
  }

  @media (max-width: 1080px) {
    &.app_shrink {
      width: calc(100vw);
    }
    grid-template-columns: 50px 1fr;

    .side_navigation_close {
      width: 50px !important;
    }
    .side_navigation {
      width: 200px;
      .side_nav_header {
        height: 50px;
        padding: 10px;
        svg {
          width: 30px;
          height: 30px;
          min-width: 30px;
        }
        .side_nav_logo {
          width: 85px;
          height: 30px;
          min-width: 85px;
        }
      }
      .side_nav_body {
        padding: 20px 0 20px 15px;
      }
    }
  }
}

.logout_popup {
  background-color: none;
  padding: 20px 40px 20px 20px;
  cursor: pointer;
  background-color: $dark-bg-2;
  border-radius: 4px;
}

.op {
  display: none;
}

.user-popover {
  .MuiPaper-root {
    translate: 0 12px;
    border-radius: 15px;
    box-shadow: 0 5px 20px $box-shadow;
    display: grid;
    gap: 10px;
    border: 1px solid $component-background;
    min-width: 150px;
    .logout_popup {
      padding: 10px 15px;
      display: grid;
      gap: 6px;
      grid-template-columns: min-content 1fr;
      font-size: 14px;
      font-weight: 500;
      color: $white;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
