@import './colors.scss';

.tags_wrapper {
  display: flex;
  padding: 10px 0px;
  align-items: flex-start;
  align-content: center;
  gap: 5px;
  flex-wrap: nowrap;

  .tag {
    padding: 2px 10px;
    color: $sec-ui;
    gap: 10px;
    text-align: center;
    width: fit-content;
    border-radius: 20px;
    background: $placeholder;

    @if $dark-theme {
      color: $white;
    }
  }
}

.success_text {
  color: $success;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.danger_text {
  color: $danger;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.common_label_text {
  color: $white;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.28px;
}

.common_error_text {
  color: $error-red;
  font-size: 12px;
}

.size_xs {
  height: 24px;
}

.size_sm {
  height: 32px;
}

.size_md {
  height: 37px;
}

.size_lg {
  height: 56px;
}

.no-data-row {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 50%);
  width: fit-content;
  color: $placeholder;
}

.button_wrapper {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.MuiAutocomplete-listbox {
  li {
    font-size: 14px;
    min-height: 32px;
    padding: 6px 16px !important;
    line-height: 20px;
  }
}

.togglerContainer {
  .common_input_label {
    font-size: 14px;
  }
}
