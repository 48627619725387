@import '@shared-styles/src/assets/scss/colors.scss';

.orders_wrapper {
  height: 100%;
  display: flex;
  width: 100%;
  gap: 10px;

    @media (max-width: 535px) {
    flex-direction: column-reverse;
  }

  .order {
    height: 100%;
    width: calc(100% - 48px);
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
    overflow: auto;

    @media (max-width: 535px) {
      width: 100%;
    }
    .order_search_container {
      width: 100%;
      border-radius: 20px;
      display: flex;
      align-items: center;
      gap: 20px;
      position: relative;
      z-index: 5;
    }

    .orders_table_container {
      thead,
      tbody {
        tr {
          td,
          th {
            &:first-child {
              position: sticky;
              left: 0;
              z-index: 2;
              &:not(td) {
                z-index: 3;
              }
            }
          }
        }
      }
    }

    th#Cart {
      div {
        display: flex;
        align-items: center;
        gap: 15px;

        button {
          background: none;
          border: none;
          color: $sub-text;
        }
      }
    }
  }
}
