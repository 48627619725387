@import '@shared-styles/src/assets/scss/colors.scss';
.invite-card {
  background-color: transparent;
  border-radius: 8px;
  padding: 30px;
  text-align: center;
  color: $white;
  font-family: Arial, sans-serif;
  width: 300px;
  margin: auto;

  border: 1px solid $phoenix-background;

  .invite-header {
    margin-bottom: 20px;

    .phoenix-logo {
      width: 100px;
    }
  }

  .invite-message {
    margin-bottom: 10px;
    font-size: 16px;

    .invite-store {
      font-weight: bold;
    }
  }

  .invite-email {
    margin-bottom: 20px;
    font-size: 14px;
  }

  .invite-buttons {
    display: flex;
    justify-content: space-between;

    .invite-button {
      padding: 10px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;

      &.reject {
        background-color: #3b3e45;
        color: white;
      }

      &.accept {
        background: linear-gradient(
          100.7deg,
          #f0b351 0%,
          #f0b351 10.43%,
          #ff5acd 100%,
          #6750a4 100%
        );

        color: white;
      }
    }
  }
}
