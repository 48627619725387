@import '@shared-styles/src/assets/scss/colors.scss';

.order_detail_header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  .shopify_header {
    color: $white;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.32px;

    .shopify_value {
      color: $sec-ui;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: -0.32px;
      text-decoration-line: underline;
    }
  }

  .order_header_wrapper {
    display: flex;
    align-items: center;
    gap: 15px;

    .order_id {
      color: $white;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: -0.6px;
    }

    .badge_wrapper {
      display: flex;
      align-items: center;
      gap: 5px;

      .badge_label {
        color: $white;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: -0.362px;
      }

      .tags_wrapper {
        .tag {
          color: $sec-ui;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          white-space: nowrap;
        }
      }
    }
  }
}
