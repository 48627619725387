@import '@shared-styles/src/assets/scss/colors.scss';

.userPermissionss {
  .multiLevelSelect {
    display: grid;
    grid-template-rows: min-content 0px;
    gap: 5px;
    max-height: 44px;
    & > div:nth-child(2) {
      height: fit-content;
    }
    .common-multi-select-input {
      gap: 0;
      min-height: 44px;
    }
    .input-label-permission {
      font-size: 12px;
      color: $white;
      margin-bottom: 2px;
    }
    .dropdown-input {
      display: none;
    }
    .dropdown-list {
      width: 100%;
      background-color: $component-background;
      li {
        color: $white;
        margin: 0;
        &:hover {
          color: $white;
          background-color: $dark-bg-2;
        }
        .nested-submenu {
          background-color: $component-background;
          box-shadow: 6px 6px 50px $box-shadow;
        }
      }
    }
    .stores-dropdown-list {
      width: 100%;
      left: 0;
      background-color: transparent !important;
      position: unset;
      ul {
        max-height: 300px;
        overflow: auto;
        margin: 0 !important;
        border-radius: 10px;
        background-color: $component-background;
        li {
          position: unset;
        }
        .dropdown-title {
          display: block;
          position: sticky;
          top: 0;
          padding: 5px 10px;
          background-color: $component-background;
          font-size: 12px;
          color: $sub-text;
          font-weight: 500;
        }
        .nested-submenu {
          transition: all 0.25s ease;
          transform: none;
          top: calc(var(--mouse-y) - 30px);
          left: calc(100% - 10px);
          width: max-content;
          border-radius: 10px;
        }
      }
    }
  }
}
.main-submenu {
  left: 100%;
  transform: translateY(-50%);
}

.active {
  background-color: $icon-background !important;
}
