@import '@shared-styles/src/assets/scss/colors.scss';

.page {
  position: relative;
}

.pop-content {
  width: 370px;
  min-width: 150px;
  display: flex;
  flex-direction: column;
  .content {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
}
.carts-popover {
  .MuiPaper-root {
    border-radius: 12px;
  }
}
.cart-items {
  background: $dark-bg-2;
  border-radius: 12px;
  overflow: auto;
  padding: 20px;
  color: $white;
  .carts-list {
    max-height: 250px;
    overflow: auto;
    margin: 10px 0;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: $dark-bg-2;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      background: $sub-text;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $placeholder;
    }

    &::-webkit-scrollbar-corner {
      background: transparent;
    }
  }
  .item {
    border-bottom: 1px solid $border;
    padding: 10px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    &:last-child {
      border: none;
    }
    .product-wrap {
      display: flex;
      align-items: self-start;
      justify-content: center;
      gap: 10px;
      .item-content {
        padding: 5px 0;
        font-size: 14px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: baseline;
        gap: 2px;
        .qty-wrap {
          color: $sub-text;
          display: flex;
          align-items: center;
        }
        .offer {
          color: $sub-text;
          text-decoration: line-through;
        }
        .vip-price {
          color: $sec-ui;
          text-decoration: underline;
        }
        .final-amt {
          color: $success;
        }
        .item-name {
          font-size: 18px;
          max-width: 150px;
        }
      }
    }
  }
  .summary-content {
    .summary-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      .sum-content {
        width: 100%;
        .total {
          border-bottom: 1px solid $border;
          border-top: 1px solid $border;
        }
        p {
          display: flex;
          width: 100%;
          justify-content: space-between;
          gap: 5px;
          padding: 10px 0;
          font-size: 16px;
          color: $sub-text;
          span {
            color: $white;
          }
        }
      }
    }
    .saved-amt {
      display: flex;
      padding: 0 15px;
      color: $sub-text;
      span {
        color: $success;
      }
    }
  }
}
