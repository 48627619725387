@import '@shared-styles/src/assets/scss/colors.scss';
.h-full {
  .h-full {
    .Common_Table {
      .table {
        .table-body {
          min-height: 250px;
          .table_cell {
            .text-as-link {
              cursor: pointer;
              color: $sec-ui;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;

              &:hover {
                text-decoration-line: underline;
              }
            }
          }
        }
      }
    }
  }
}
