@import '@shared-styles/src/assets/scss/colors.scss';

@mixin legend {
  display: flex;
  gap: 8px;
  color: $helper-text;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.28px;
  white-space: nowrap;

  .indicator {
    border-radius: 15px;
    width: 15px;
    height: 15px;
  }
}

.salvage_wrapper {
  height: 100%;
  display: flex;
  width: 100%;
  gap: 10px;

    @media (max-width: 535px) {
    flex-direction: column-reverse;
  }
}

.white-space-nowrap {
  white-space: nowrap;
}

.subscription_salvage {
  position: relative;
  height: 100%;
  width: calc(100% - 40px);
  padding-right: 5px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  overflow: auto;

   @media (max-width: 535px) {
    width: 100%;
  }
  .subscription_salvage_container {
    .subscription_salvage_top_container {
      height: 100%;
      // width: calc(100% - 48px);
      display: flex;
      flex-direction: column;
      gap: 15px;
      position: relative;
      min-height: 150px;

      .subscription_salvage_search_container {
        width: 100%;
        height: 75px;
        border-radius: 20px;
        background-color: $dark-bg-2;
        border-radius: 20px;
        display: flex;
        align-items: center;

        .search_container {
          align-items: center;
          margin-left: 20px;
          display: flex;
          gap: 10px;
          border-radius: 22px;
          height: 45px;
          width: 800px;
          background-color: $component-background;

          svg {
            margin-left: 20px;
          }

          .search_input {
            width: 90%;
          }
        }
      }
    }
  }
  .lg_card_container {
    border-radius: 12px;
    background: $dark-bg-2;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 38px;

      .header_text {
        color: $white;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
      }
    }
    .horizontal_legends {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      flex-wrap: wrap;
      .each-ratio-data {
        color: $white;
        font-weight: 600;
        font-size: 24px;
      }
      .legend {
        @include legend;
      }
    }
  }
}
.subscription_chart {
  canvas {
    max-width: 100% !important;
  }
}
.approval-stats {
  > div {
    border-color: $border;
  }
}
