@import '@shared-styles/src/assets/scss/colors.scss';
.Common_Table {
  .table_cell {
    .text-as-link {
      cursor: pointer;
      color: $sec-ui;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;

      &:hover {
        text-decoration-line: underline;
      }
    }
  }
  &.settingsTable {
    .MuiTableCell-root {
      padding: 16px !important;
    }
  }
}
