.transaction_container_log
  .transaction_top_container
  .transactions_table_container
  .transactions_table
  .table_header {
  position: sticky;
  top: 0;
}
.transaction_container_log
  .transaction_top_container
  .transactions_table_container
  .transactions_table
  .table_header
  tr
  th:first-child {
  position: unset !important;
}
.transaction_container_log
  .transaction_top_container
  .transactions_table_container
  .transactions_table
  .table_header
  tr
  th:nth-child(2) {
  position: unset;
}

.transaction_container_log
  .transaction_top_container
  .transactions_table_container
  .transactions_table
  .table_body
  tr
  th:first-child {
  position: unset !important;
}
.transaction_container_log
  .transaction_top_container
  .transactions_table_container
  .transactions_table
  .table_body
  tr
  th:nth-child(2) {
  position: unset !important;
}
.transaction_container_log
  .transaction_top_container
  .transactions_table_container
  .transactions_table
  .table_body
  tr
  th {
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}
