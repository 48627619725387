@import '../../../assets/scss/colors.scss';

.custom-toster {
  .Toastify__close-button {
    color: $black;
    opacity: unset;
    align-self: auto;

    svg {
      height: 20px !important;
      width: 20px !important;
    }
  }

  .Toastify__toast {
    min-height: fit-content !important;
  }

  .Toastify__toast-theme--colored {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: -0.02em;
    text-align: left;
    background: #141e2d;
    color: $black !important;
  }

  .Toastify__toast--success {
    background: linear-gradient(0deg, #05cd99, #05cd99) !important;

    border: 1px solid #a3aed066;
    box-shadow: -2px 4px 50px 0px #05cd9966;
  }

  .Toastify__toast--error {
    border-radius: 10px !important;
    border: 1px solid rgba(163, 174, 208, 0.4) !important;
    background: #e84040 !important;
    box-shadow: -2px 4px 50px 0px rgba(232, 64, 64, 0.4) !important;
  }

  .Toastify__toast--warning {
    background: linear-gradient(0deg, #ffc940, #ffc940) !important;
    border: 1px solid #a3aed066;
    box-shadow: -2px 4px 50px 0px rgba(255, 201, 64, 0.4);
  }

  .Toastify__toast--info {
    background: linear-gradient(0deg, #a3aed0, #a3aed0) !important;
    box-shadow: -2px 4px 50px 0px #00000066;
    border: 1px solid #a3aed066;
  }

  .Toastify__toast--default {
    background: linear-gradient(0deg, #0b111a, #0b111a) !important;
    box-shadow: -2px 4px 50px 0px #00000066;
    border: 1px solid #a3aed066;
    color: $white !important;

    .Toastify__close-button--colored {
      color: $white !important;
    }

    .Toastify__toast-theme--colored {
      color: $white !important;
    }
  }
}