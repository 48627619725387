@import '../../../assets/scss/colors.scss';

.stepper-section {

    .stepper-wrapper-1 {
        position: relative;

        .stepper-position {
            width: 100%;

            .stepper {
                display: flex;
                isolation: isolate;
                justify-content: space-between;

                &::before {
                    content: '';
                    position: absolute;
                    left: 35px;
                    top: 10px;
                    width: calc(100% - 70px);
                    border-bottom: 2px solid var(--stepper-line-color, #e4e4e4) !important;
                    z-index: -1;
                }

                .step {
                    width: 80px;
                    flex: none;
                    display: flex;
                    justify-content: center;

                    .MuiStepConnector-horizontal {
                        display: none;
                    }

                    .MuiStepLabel-iconContainer {
                        &.Mui-disabled {
                            svg {
                                color: #485063;
                            }
                        }
                    }

                    .stepLabel {
                        >span {
                            width: 23px;
                            display: flex;
                        }

                        svg {
                            outline: 10px solid #f1f4fb;
                            border-radius: 50%;
                        }

                        .MuiStepLabel-iconContainer {
                            svg {
                                text {
                                    fill: #333;
                                }

                                path {
                                    fill: Var(--stepper-color, #f90182)
                                }
                            }

                            .Mui-completed,
                            .Mui-active {
                                color: var(--stepper-color, #f90182) !important;

                                text {
                                    fill: #ffffff !important;
                                }

                            }
                        }

                        .MuiStepLabel-label {
                            color: #333;
                        }

                        &.Mui-disabled {
                            svg {
                                circle {
                                    fill: #f8f8f8;
                                    stroke: #b0bce155;
                                }
                            }

                            .MuiStepLabel-label {
                                opacity: 0.6;
                            }
                        }
                    }
                }
            }
        }
    }
}

.stepper-item.stepper-item.stepper-item {
    padding: 2px 2px;
    border-radius: 10px;

    &.active {
        background: $white !important;
        color: $dark-bg-2 !important;

        .filter_form_input.filter_form_input.filter_form_input {
            .relative {
                input {
                    color: $dark-bg-2 !important;
                    background: $white !important;
                    border-color: $border !important;
                }
            }
        }

    }
}