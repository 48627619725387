@import '@shared-styles/src/assets/scss/colors.scss';
.action_log_table_container {
  border-radius: 10px;
  .merchant-table {
    .table-head {
      background-color: #141e2d !important;
      color: #ffffff;
      .table-head-row {
        .table-cell-head {
          color: #ffffff;
          padding: 5px;
          border-bottom: none;
        }
      }
    }

    .table-body {
      .table-row {
        .table-cell {
          color: #ffffff;
          border-bottom: 1px solid $border-bottom;
        }
        .table-cell-padding-1 {
          padding: 5px;
        }
        .table-cell-padding-2 {
          padding: 16px;
        }
        &:last-child {
          .table-cell {
            border-bottom: none;
          }
        }
      }
    }
  }
}
