@import '@shared-styles/src/assets/scss/colors.scss';

.text-as-link {
  cursor: pointer;
  color: $sec-ui;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  &:hover {
    text-decoration-line: underline;
  }
}
