@import '@shared-styles/src/assets/scss/colors.scss';

.onboarding-container {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  height: 100%;
  padding: 0 35px;

  .onboarding-contant {
    width: 400px;
  }
}

.onboarding-fields {
  margin: 20px 0 0;

  .onboarding-field {
    padding-bottom: 15px;

    .onboarding-input {
      margin-bottom: 0;
    }

    .field-container {
      padding-bottom: 15px;
    }
  }
}

.heading-text-area {
  .text-bold {
    padding-bottom: 6px;
    font-size: 22px;
    font-weight: 600;
  }

  .text {
    font-size: 13px;
    color: $sub-text;
  }
}

.button-field {
  display: flex;
  justify-content: space-between;

  .button {
    button {
      margin-top: 10px;
    }

    .wrapper {
      background-color: inherit;
      border: none;
      color: $white;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }

    .submit {
      padding: 11px 16px;
    }
  }
}
