.refund-field {
    &.disabled {
        .Mui-disabled {
            -webkit-text-fill-color: rgb(from var(--white) r g b / 80%);
        }
    }
}

.refund_th {
    position: sticky;
    top: 0;
    z-index: 1;
}

.Common_Table {
    table {
        tbody {
            tr {
                td {
                    &.product-name {
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
}

.content-style {
    max-height: calc(100vh - 50px) !important;
}
