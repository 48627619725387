body {
  --sec-ui: #6ad2ff;
  --black: #000;
  --white: #fff;
  --phoenix-background: #0b111a;
  --phoenix-gradient: linear-gradient(
    100deg,
    #f0b351 0%,
    #f0b351 10.43%,
    #ff5acd 100%,
    #6750a4 100%
  );
  --phoenix-gradient-v2: linear-gradient(
    200deg,
    #f0b351 0%,
    #f0b351 10.43%,
    #ff5acd 100%,
    #6750a4 100%
  );
  --dark-bg-2: #141e2d;
  --sub-text: #a3aed0;
  --placeholder: #a3aed09a;
  --border-bottom: #a3aed01a;
  --icon-background: #a3aed044;
  --helper-text: #a3aed0;
  --component-background: #212836;
  --popup-background: #1f2532;
  --border: #b0bce133;
  --divider: #a3aed010;
  --dark-bg: #0b111a;
  --nested-table-bg: #1a202c;

  --background1: rgba(163, 174, 208, 0.4);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --stepper-line: #2a313f;
  --stepper-disabled: #485063;
  --table-border: rgba(163, 174, 208, 0.1);
  --table-hover: rgba(255, 255, 255, 0.08);
  --table-active: rgba(144, 202, 249, 0.16);
  --tooltip-shadow: -2px 6px 50px 0px #00000066;
  --overlay: rgba(0, 0, 0, 0.5);

  --light-border: transparent;

  --dark-theme: '';


  &.light {
    --sec-ui: #27a7df;
    --black: #fff;
    --white: #333;
    --phoenix-background: #f1f4fb;
    --dark-bg-2: #fff;
    --sub-text: #717b97;
    --placeholder: #717b979a;
    --border-bottom: #717b971a;
    --component-background: #f8f8f8;
    --popup-background: #fff;
    --icon-background: #717b9717;
    --helper-text: #717b97;
    --border: #b0bce155;
    --divider: #717b9710;
    --dark-bg: #f4f7fe;
    --nested-table-bg: #fafafa;
    --dark-bg: #f4f7fe;

    --background1: rgba(163, 174, 208, 0.4);
    --table-border: rgba(163, 174, 208, 0.1);
    --box-shadow: rgba(0, 0, 0, 0.1);
    --stepper-line: #e4e4e4;
    --stepper-disabled: #485063;
    --table-border: rgba(163, 174, 208, 0.15);
    --table-hover: rgba(0, 0, 0, 0.04);
    --table-active: rgba(25, 118, 210, 0.08);
    --tooltip-shadow: -2px 6px 50px 0px #00000022;
    --overlay: rgba(255, 255, 255, 0.5);

    --light-border: #b0bce155;

    --dark-theme: 'true';
  }
}

$black: var(--black);
$white: var(--white);
$alwaysWhite: #fff;
$alwaysBlack: #000;
$phoenix-background: var(--phoenix-background);
$phoenix-gradient: var(--phoenix-gradient);
$phoenix-gradient-v2: var(--phoenix-gradient-v2);
$prim-ui: #f90182;
$dark-bg-2: var(--dark-bg-2);
$error-red: #ff0000;
$sub-text: var(--sub-text);
$yellow: #f0b351;
$success: #05cd99;
$danger: #e84040;
$orange: #f36337;
$placeholder: var(--placeholder);
$sec-ui: var(--sec-ui);
$border-bottom: var(--border-bottom);
$component-background: var(--component-background);
$error-border: #730000;
$popup-background: var(--popup-background);
$icon-background: var(--icon-background);
$helper-text: var(--helper-text);
$border: var(--border);
$divider: var(--divider);
$dark-bg: var(--dark-bg);
$nested-table-bg: var(--nested-table-bg);
$dark-green: #007c5c;
$green: #0c5132;
$blue-green: #02ffbd;
$light-green: #cdfee1;
$brown: #8e1f0b;
$light-brown: #fedad9;
$blue: #00527c;
$default-font:
  work-sans,
  -apple-system,
  BlinkMacSystemFont,
  'Segoe UI',
  Roboto,
  Oxygen,
  Ubuntu,
  Cantarell,
  'Fira Sans',
  'Droid Sans',
  'Helvetica Neue',
  sans-serif;

$background1: var(--background1);
$table-border: var(--table-border);
$table-hover: var(--table-hover);
$table-active: var(--table-active);
$box-shadow: var(--box-shadow);
$stepper-line: var(--stepper-line);
$stepper-disabled: var(--stepper-disabled);
$tooltip-shadow: var(--tooltip-shadow);
$dark-theme: var(--dark-theme);
$overlay: var(--overlay);
$light-border: var(--light-border);
