@import '@shared-styles/src/assets/scss/colors.scss';

.task_container {
  height: 100%;
  display: flex;
  width: 100%;
  gap: 10px;

  .task_top_container {
    height: 100%;
    width: calc(100% - 48px);
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
    overflow: auto;

    .task_search_container {
      width: 100%;
      border-radius: 20px;
      display: flex;
      align-items: center;
      gap: 20px;
      position: relative;
      z-index: 5;
    }

    .task_message {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
