@import '@shared-styles/src/assets/scss/colors.scss';

.cart-items {
  .carts-list {
    .item {
      padding: 8px 0;

      .product-wrap {
        width: 100%;

        .product-image {
          height: 35px;
          width: 35px;
          border-radius: 6px;
        }

        .item-content {
          padding: 0;
          width: 100%;

          .item-name {
            max-width: none;
            font-size: 16px;
          }

          .qty-wrap {
            width: 100%;
          }

          .total-product-amount {
            width: fit-content;
            margin-left: auto;
            font-size: 16px;
            color: $white;
          }
        }
      }
    }
  }

  .summary-content {
    .summary-container {
      .sum-content {
        .vip-price {
          color: $success;
        }
        .total {
          font-weight: 500;
        }
      }
    }
  }
}
.order-cart {
  display: flex;
  align-items: center;
  button {
    display: flex;
  }
}
