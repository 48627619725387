@import '@shared-styles/src/assets/scss/colors.scss';
.timezone-lists {
  background: $component-background !important;
  li {
    word-break: break-word;
    font-size: 12px;
  }

  &.any-list {
    ul {
      padding-left: 0;
      li {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}
