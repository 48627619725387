.split-layout-grid {
  display: flex;
  width: 100%;
  min-height: 100vh;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .left-split-layout,
  .right-split-layout {
    padding-left: 7rem;
    padding-right: 7rem;
  }
}

@media (min-width: 1024px) {
  .display-on-mobile-only {
    display: none;
  }
  .right-dropzone-visible {
    display: block !important;
  }
}

@media (max-width: 1024px) {
  .split-layout-grid {
    flex-direction: column-reverse;
    .split-layout-child {
      width: 100% !important;
    }
  }
}

.border {
  border: 1px solid #ccc;
}
