@import '@shared-styles/src/assets/scss/colors.scss';
.common_multi_select_container {
  .MuiAutocomplete-root {
    &.dropdown-field {
      .MuiFormControl-root {
        &.text_field_input_multiselect {
          z-index: 1 !important;
        }
      }
    }
  }
  .error_message {
    color: $error-red;
    font-size: 12px;
  }
}
