@import '@shared-styles/src/assets/scss/colors.scss';

.order-detail_side_filter_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 40px;
  width: 100%;
  align-items: center;
  gap: 20px;

  .order-detail_side_filtericon {
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    color: $sub-text;

    &.col_filter {
      svg {
        margin: 2px -2px -2px 2px;
      }
    }

    &.margin_filter {
      svg {
        margin: 1px 0 -1px 0;
      }
    }

    &.active {
      color: $white;
    }
  }

  .filter_selected_wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px;
    text-align: left;
    width: 290px;
    position: fixed;
    background-color: $dark-bg-2;
    height: 100vh;
    top: 0;
    right: 0;

    .filter_header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;

      .close_icon {
        cursor: pointer;
      }
    }

    .filter_container {
      overflow: auto;
    }
  }
}
