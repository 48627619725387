@import '@shared-styles/src/assets/scss/colors.scss';

.disclosure_modal {
  backdrop-filter: blur(2px);

  .MuiBox-root {
    background-color: $component-background;
  }
  .disclosure_container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    .disclosure_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .heading-container {
        .heading {
          font-size: 22px;
          font-weight: 440;
          line-height: 25.81px;
          text-align: center;
          color: $white;
        }
        .sub_heading {
          font-size: 20px;
          font-style: italic;
          font-weight: 200;
          line-height: 25.81px;
          color: $white;
        }
      }
      .phoenix_icon {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }
    .disclosure {
      display: flex;
      gap: 20px;
      max-height: calc(100vh - 240px);
      overflow: auto;
      flex-direction: column;
      .disclosure-text {
        font-family: $default-font;
        font-weight: 400;
        font-size: 14px;
        color: $white;
        line-height: 22px;
        padding-bottom: 10px;
      }
    }
    .div-wrapper {
      display: flex;
      align-items: center;
      gap: 6px;
      font-size: 14px;
      color: $sub-text;
      font-weight: 400;
      .MuiButtonBase-root {
        padding: 0;
      }
    }
    .button_box {
      margin: auto;
      .button {
        padding: 10px 15px;
        color: $dark-bg-2;
        border-radius: 10px;
        background: $sub-text;
        box-shadow: -2px 4px 20px 0px rgba(0, 0, 0, 0.1);
      }
    }
  }
}
