.split-layout-grid {
  display: flex;
  width: 100%;
  min-height: 100vh;
}

@media (min-width: 1024px) {
  .display-on-mobile-only {
    display: none;
  }
  .right-dropzone-visible {
    display: block !important;
  }
}

@media (max-width: 1024px) {
  .split-layout-grid {
    flex-direction: column-reverse;
    .split-layout-child {
      width: 100% !important;
    }
  }
}

@media (min-width: 768px) {
  .header-dropdzone {
    .standardLayoutPadding {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }
}

.header-dropdzone {
  ._DropZone-content_djoti_10 {
    min-height: 0px !important;
  }
}

.split-layout {
  &.header-dropdzone {
    ._DropZone-content_djoti_10 {
      min-height: 65px;
    }
  }
}

@media (max-width: 768px) {
  .header-dropdzone {
    .standardLayoutPadding {
      padding-left: 0.25rem !important;
      padding-right: 0.25rem !important;
    }
  }
}

.border {
  border: 1px solid #ccc;
}
