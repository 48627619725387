@import '@shared-styles/src/assets/scss/colors.scss';

.status_badge {
  padding: 3px 12px;
  border-radius: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  text-transform: capitalize;
  width: fit-content;
  text-align: center;
}

.dispute_status {
  color: $white;
  border: 1px solid $placeholder;
  background: $orange;
}

.failed_status {
  color: #ffffff;
  border: 1px solid $placeholder;
  background: #e84040;
}

.success_status {
  color: #141e2d;
  border: 1px solid $placeholder;
  background: #05cd99;
}

.pending_status {
  border: 1px solid $placeholder;
  background: $sub-text;
  color: #141e2d;
}

.authorized_status {
  border: 1px solid $placeholder;
  background: #98ffe4;
  color: #0c5132;
}
.partial_status {
  border: 1px solid $placeholder;
  background: #b7993f;
  color: #ffffff;
}

.voided_status {
  border: 1px solid $placeholder;
  background: #8491b9;
  color: #141e2d;
}

.active_status {
  border: 1px solid $placeholder;
  background: #e0f0ff;
  color: $blue;
}

.chargeback_status {
  border: 1px solid $placeholder;
  background: #cc2222;
  color: #ffffff;
}

.refund_status {
  background: #2b539e;
  color: #ffffff;
  border: 1px solid $placeholder;
}

.recovered_status {
  border: 1px solid $placeholder;
  background: #32cd32;
  color: #ffffff;
}

.created_status {
  border: 1px solid $placeholder;
  background: #ffd700;
  color: #000000;
}
