@import '@shared-styles/src/assets/scss/colors.scss';

.customer-details-page {
  display: grid;
  grid-template-rows: min-content 1fr;
  position: relative;
  height: 100%;
  gap: 15px;

  .details {
    display: flex;
    gap: 15px;
    height: fit-content;

    .profile-info {
      border: 1px solid $background1;
      border-radius: 15px;
      background: $dark-bg-2;
      padding: 15px;
      height: fit-content;

      .name-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;

        .more-info-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          padding-left: 10px;
          align-items: baseline;
          gap: 20px;
          font-size: 14px;

          .content-wrap {
            display: flex;
            gap: 20px;
            justify-content: center;
            align-items: center;
          }
        }

        .image {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;

          img {
            width: 50px;
            height: 50px;
          }

          .name-wrapper {
            .name-wrap {
              width: max-content;
              display: flex;
              flex-direction: column;
              font-size: 18px;
              gap: 3px;

              span {
                font-size: 12px;
                color: $sec-ui;
                text-align: left;
                text-decoration-line: underline;
              }
            }
          }
        }
      }
    }

    .other-info {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
      gap: 10px;
      width: 100%;
      height: fit-content;

      .container-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .content-wrap {
          display: flex;
          gap: 14px;
          border-radius: 12px;
          background: var(--dark-light, $dark-bg-2);
          padding: 10px;
          align-items: center;
          height: 100%;
          width: 100%;

          .icon {
            width: 46px;
            height: 46px;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $icon-background;
            border-radius: 35px;

            svg {
              transform: scale(0.8);
            }
          }

          .content-wrapper {
            text-align: justify;
            margin: 0 10px 0 0;
            align-items: baseline;
            font-size: 14px;
            display: flex;
            flex-direction: column;
            gap: 5px;
            color: $sub-text;

            .address-dropdown {
              width: 100%;
              border-radius: 20px;
              background: $dark-bg-2;
              box-shadow: -2px 4px 30px 0px $box-shadow;
              min-width: 300px;
              top: calc(100% + 5px);
              left: 0;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              border: none;
              display: flex;
              position: absolute;
              border: 1px solid;
              border: none;
              z-index: 9;

              .more-info {
                color: $white;
                display: flex;
                padding: 15px 16px;
                align-items: center;
                gap: 12px;
                align-self: stretch;
                border-bottom: 1px solid $border-bottom;

                &:last-child {
                  border-bottom: none;
                }
              }
            }

            .title {
              display: flex;
              align-items: center;
              width: 100%;
              cursor: pointer;
              justify-content: space-between;
              font-size: 13px;
            }

            .status {
              text-transform: capitalize;

              &.active {
                color: $success;
                background-color: inherit !important;
              }

              &.canceled {
                color: $danger;
              }

              &.salvage {
                color: $orange;
              }

              &.paused {
                color: $sub-text
              }
            }

            span {
              color: $white;
              font-size: 14px;
            }
          }
        }
      }
    }

    .action-icon {
      // margin: 20px 0;
    }
  }

  .tab-content {
    height: 100%;
    display: grid;
    grid-template-rows: min-content 1fr;

    .tab-content-wrapper {
      overflow: auto;

      >div {
        height: 100%;

        .Common_Table {
          height: 100%;
        }
      }
    }
  }

  @media (max-width: 560px) {
    .details {
      flex-direction: column;
    }

    .tab-content {
      min-height: 500px;
    }
  }
}

.notes-pop-content {
  .popover-child {
    display: flex;
    max-height: 240px;
    padding: 10px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    align-self: stretch;
    background-color: $dark-bg-2;

    .child-elements {
      display: flex;
      padding: 7px 20px;
      align-items: center;
      gap: 8px;
    }
  }
}