@import '../../../assets/scss/colors.scss';

.lg_card_container,
.md_card_container {
  position: relative;

  .loader-container {
    position: absolute;

    .loader {
      position: absolute !important;
    }
  }
}
.loader-container.page-builder-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px);
  background-color: $overlay;
  z-index: 999;

  .loader {
    position: sticky;
    bottom: calc(50% - 55px);
    top: calc(50% - 55px);
    left: unset;
    transform: unset;
  }

  .svg-img {
    display: flex;
    justify-content: center;

    .svg-loader {
      width: 90px;
      height: 90px;
    }
  }

  .loader_loading-bar-contain {
    .loading-bar {
      background-color: transparent;
      border-radius: 20px;

      span {
        background-image: $phoenix-gradient !important;
        border-radius: 20px;
      }
    }
  }

  .loading-spinner {
    .spinner {
      color: $phoenix-gradient !important;
    }
  }
}
