.checkout-flow {
  .h-full {
    .Common_Table {
      .table-cell {
        .input_edit {
          width: 200px;
          height: 42px;
          padding: 12px 16px 12px 16px;
          border-radius: 10px;
          background-color: #212836;
          border: none;
        }
        input:focus {
          outline: none;
          border: none;
        }
      }
    }
  }
  .transaction_for_submit {
    float: right;
    margin: 10px;
    width: 120px;
    background: var(--dark-bg-2, #141e2d);
    height: 40px;
  }
}
