@import '@shared-styles/src/assets/scss/colors.scss';
.user_permission {
  .shopify_wrapper {
    max-height: 600px;
    display: flex;
    width: 100%;
    .store_management {
      width: 100%;
      display: grid;
      grid-template-rows: 1fr min-content;
      position: relative;
    }
  }
}
