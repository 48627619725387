@import '@shared-styles/src/assets/scss/colors.scss';

.transaction_history_container {
  display: flex;
  width: 100%;
  gap: 10px;

  .content-wrap {
    width: 100%;
    position: relative;
  }
}
