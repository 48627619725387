.Flex {
  display: flex;
  min-height: 0;
  min-width: 0;
  flex-wrap: wrap;
}

.Flex-item {
  flex: 1;
}
