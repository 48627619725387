@import '@shared-styles/src/assets/scss/colors.scss';
.order-detail_refund {
  .store-event-notes {
    display: flex;
    flex-direction: column;
    .label {
      color: $white;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: -0.28px;
      margin-top: 2px;
    }
    .addnotes-container {
      display: flex;
      flex-direction: row;
      align-items: center;

      .addnotes-box {
        width: 100%;
        height: 100px;
        background-color: $component-background;
        border: none;
        @if $dark-theme {
          border: 1px solid $border;
        }
        font-family:
          work-sans,
          -apple-system,
          BlinkMacSystemFont,
          'Segoe UI',
          Roboto;
        border-radius: 7px;
        padding: 12px 16px;
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        color: $white;
        margin: 10px;
        margin-left: 0;
        resize: vertical;
      }

      .addnotes-box:focus {
        outline: none;
        box-shadow: none;
      }

      .addnotes-box::placeholder {
        overflow: hidden;
        color: $placeholder;
        text-overflow: ellipsis;
        font-family:
          work-sans,
          -apple-system,
          BlinkMacSystemFont,
          'Segoe UI',
          Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: -0.28px;
      }
    }
  }
  .error_message {
    color: $error-red;
    font-size: 12px;
  }
}

.model-content{
&:has(.black_list_popup ){
    overflow: visible !important;
  }
}