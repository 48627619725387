@import '@shared-styles/src/assets/scss/colors.scss';

.action_filter_container {
  .action_filter_columns {
    padding: 10px 0;
    border-bottom: 1px solid $border-bottom;
    color: $sub-text;
    cursor: pointer;
  }
}

.popup-section {
  width: 450px;
  color: $white;

  .multiselect,
  .select,
  .text {
    margin-bottom: 10px;
  }

  .multiselect {
    .statusOption {
      .MuiFormLabel-root {
        &.MuiFormLabel-colorPrimary {
          color: $white;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          letter-spacing: -0.28px;
        }
      }

      .MuiFormGroup-root {
        &.custom_radio_group {
          padding: 4px 0px;
        }
      }
    }

    .cancelCheckbox {
      padding: 0;
    }

    .checkbox_label {
      padding-left: 6px;
      font-size: 15px;
      font-style: normal;
      letter-spacing: -0.32px;
    }
  }

  .checkbox {
    display: flex;
    align-items: center;
    justify-self: center;
    gap: 10px;
    font-size: 14px;

    .common_check_box {
      padding: 0;
    }
  }

  .popup-question {
    padding: 15px 0;
  }

  .amount {
    font-weight: 700;
  }

  ul {
    list-style-type: disc;
    padding-left: 25px;
    line-height: 180%;
    color: $sub-text;

    li {
      margin-bottom: 10px;
    }
  }

  .update {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    border: none;
    margin-bottom: 10px;

    .updateText {
      color: $white;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }

    .contentContainer {
      display: flex;
      gap: 5px;
      flex-direction: column;

      .content {
        display: flex;
        gap: 10px;
        align-items: center;

        .contentText {
          color: $sub-text;
          font-weight: 500;
          line-height: 20px;
          width: max-content;

          .transactionItem {
            display: flex;
            gap: 10px;
            align-items: flex-start;
          }
        }
      }
    }
  }

  .failedIcon {
    width: 10px;
    height: 10px;
    margin-top: 5px;

    path {
      fill: red;
    }
  }

  .reason {
    display: block;
    color: $sub-text;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: -0.28px;
  }

  .outlet {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 5px;
    align-self: stretch;

    .filter_form_input {
      width: 100%;
    }

    .border-rupee {
      display: inline-flex;
      padding-bottom: 8px;
      gap: 2px;
      align-items: center;

      .slash {
        color: $placeholder;
        font-family: $default-font;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
      }

      .rupee {
        color: $sub-text;
        font-family: $default-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: inline-block;
      }
    }
  }
}

.flex-container {
  display: flex;
  justify-content: center;
  margin: 10px;
}
