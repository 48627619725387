@import '@shared-styles/src/assets/scss/colors.scss';

@mixin common_checkbox_wrapper {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: -8px;

  .checkbox_label {
    color: $sub-text;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.32px;
  }
}

.action_filter_container {
  .no_actions {
    color: $sub-text;
  }
  .action_filter_columns {
    padding: 10px 0;
    border-bottom: 1px solid $border-bottom;
    color: $sub-text;
  }
}

.order-detail_void {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;

  .checkbox_wrapper {
    @include common_checkbox_wrapper;
  }
}

.order-detail_refund_body {
  display: flex;
  align-items: center;
  justify-content: center;

  .order-detail_refund {
    width: 100%;
    max-width: 450px;
    display: flex;
    max-width: 450px;
    flex-direction: column;
    gap: 20px;

    .direct_amount_field {
      display: flex;
      gap: 8px;
      align-items: flex-end;
      width: 100%;

      .text_field_wrapper {
        width: 100%;
      }

      .direct_amount_text {
        color: $sub-text;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.32px;
        white-space: nowrap;

        span {
          color: $placeholder;
          font-size: 26px;
        }
      }

      .additional_margin {
        margin-bottom: 22px !important;
      }
    }

    .checkbox_wrapper {
      @include common_checkbox_wrapper;
    }
  }
}
