@import '@shared-styles/src/assets/scss/colors.scss';
.payment_gateway {
  .payment_gateway_wrapper {
    max-height: 600px;
    display: flex;
    width: 100%;
    .store_management {
      width: 100%;
      display: grid;
      grid-template-rows: 1fr min-content;
      position: relative;
      .Common_Table {
        border-radius: none !important;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        .risk_management_table {
          .table_header {
            .MuiTableRow-head {
              .table_header_cell {
                .MuiTableCell-stickyHeader {
                  background-color: rgba(163, 174, 208, 0.2) !important;
                }
              }
              .MuiTableRow-root {
                height: 48px;
                padding: 0px 12px;
                border-bottom: 1px solid rgba(163, 174, 208, 0.1);
                background-color: $dark-bg-2 !important;

                &.header_text {
                  color: $sub-text;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 600;
                  white-space: nowrap;
                }
              }
            }

            .flex-row {
              display: flex;
              align-items: center;
            }
          }

          .table_body {
            .table_row {
              .table_cell {
                height: 64px;
                padding: 0px 12px;
                white-space: nowrap;
                color: $white;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                border-bottom: 1px solid rgba(163, 174, 208, 0.1);
              }

              .link_btn {
                cursor: pointer;
                color: $sec-ui;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;

                &:hover {
                  text-decoration-line: underline;
                }
              }
            }
          }
        }
      }
    }
  }
  .add_store {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $component-background;
    padding: 5px;
  }
}
