@import '@shared-styles/src/assets/scss/colors.scss';
.invites {
  .Common_Table {
    #Actions {
      text-align: end;
    }
    .table_body {
      .button {
        padding: 5px;
        border-radius: 5px;
      }
      .action-invites {
        display: flex;
        justify-content: end;
        svg {
          path {
            fill: $white;
          }
        }
        .Accept {
          svg {
            width: 30px;
            height: 30px;
          }
          &:hover {
            svg {
              path {
                fill: $green;
              }
            }
          }
        }
        .Delete {
          svg {
            width: 28px;
            height: 28px;
          }
          &:hover {
            svg {
              path {
                fill: $error-red;
              }
            }
          }
        }
        .Decline {
          svg {
            width: 18px;
            height: 18px;
          }
          &:hover {
            svg {
              path {
                fill: $error-border;
              }
            }
          }
        }
      }
    }
  }
}
