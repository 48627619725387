@import '@shared-styles/src/assets/scss/colors.scss';
.action-logs {
  display: flex;
  flex-direction: column;
  gap: 5px;
  .accordion {
    background-color: $dark-bg-2 !important; // Adjust accordion background color
    color: #ffffff; // Adjust accordion text color
    border-radius: 10px;

    .accordion-summary {
      background-color: #141e2d !important; // Adjust summary background color
      color: #ffffff; // Adjust summary text color
    }
  }

  .table-container {
    background-color: #141e2d; // Adjust table container background color
  }

  .table-head {
    background-color: #141e2d !important;
    color: #ffffff;
  }

  .table-row {
    background-color: $dark-bg;
  }

  .table-cell {
    color: #ffffff;
  }
}
