@import '@shared-styles/src/assets/scss/colors.scss';
.pagination_container {
  .pagination-button {
    color: #fff;
    font-size: 14px;
    padding: 5px 15px;
    background: $dark-bg-2;
    border-radius: 50px;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    text-transform: none;
    border-radius: 8px;
    text-align: center;
    box-sizing: border-box;
    min-width: 32px;
    height: 32px;
    padding: 0 6px;
    margin: 0 3px;
    &active {
      background: 'rose';
    }
  }
  .pagination-button.active {
    background: #f90182 !important;
  }
  .pagination-button.disabled {
    color: grey !important;
    cursor: default;
  }
  .row_content_decider {
    display: flex;
    align-items: center;
    gap: 10px;
    .rows_page {
      color: $sub-text;
      font-size: small;
    }
    .common_select_container {
      .toggle_button_wrapper {
        min-width: 75px;
        padding: 6px 14px;
      }
    }
  }
  .pagination-details {
    text-align: center;
    font-size: small;
  }
  .dotButton {
    display: flex;
    justify-content: end;
    align-items: end;
    padding-bottom: 5px;
  }
}
