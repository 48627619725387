@import '@shared-styles/src/assets/scss/colors.scss';

.Subscription-history-container {
  position: relative;
  min-height: 200px;

  .history-content {
    padding: 15px;

    .history-wrap {
      text-align: start;
      display: grid;
      color: $sub-text;
      grid-template-columns: 200px 1fr;

      .time-wrapper {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        position: relative;
        isolation: isolate;
        text-wrap: pretty;
        .circle {
          right: 0px;
          top: 20px;
          position: absolute;
          width: 10px;
          background-color: #a3aed0;
          border-radius: 50%;
          aspect-ratio: 1;
          transform: translateX(50%);
          outline: 5px solid $stepper-disabled;
        }
        &::after {
          content: '';
          position: absolute;
          right: 0;
          height: 100%;
          width: 2px;
          background: $stepper-disabled;
          top: 25px;
          z-index: -1;
          transform: translateX(50%);
        }
      }

      &:last-child {
        .time-wrapper {
          &::after {
            display: none;
          }
        }
      }

      .history-wrapper {
        display: flex;
        flex-direction: column;
        padding: 10px 25px;

        .heading-wrap {
          font-size: 18px;
          color: $white;
        }
      }
    }
  }
}
