/* Set content for font-families */
.ql-font {
  width: 135px !important;
}
.ql-font-arial,
.ql-font span[data-value='arial']::before {
  font-family: Arial, sans-serif;
}
.ql-font-comic-sans,
.ql-font span[data-value='comic-sans']::before {
  font-family: 'Comic Sans MS', cursive, sans-serif;
}
.ql-font-courier-new,
.ql-font span[data-value='courier-new']::before {
  font-family: 'Courier New';
}
.ql-font-georgia,
.ql-font span[data-value='georgia']::before {
  font-family: Georgia, serif;
}
.ql-font-helvetica,
.ql-font span[data-value='helvetica']::before {
  font-family: Helvetica;
}
.ql-font-lucida,
.ql-font span[data-value='lucida']::before {
  font-family: 'Lucida Sans Unicode', 'Lucida Grande', sans-serif;
}
.ql-font-tahoma,
.ql-font span[data-value='tahoma']::before {
  font-family: Tahoma;
}
.ql-font-times-new-roman,
.ql-font span[data-value='times-new-roman']::before {
  font-family: 'Times New Roman';
}
.ql-font-trebuchet,
.ql-font span[data-value='trebuchet']::before {
  font-family: 'Trebuchet MS';
}
.ql-font-verdana,
.ql-font span[data-value='verdana']::before {
  font-family: Verdana;
}

.ql-font-poppins,
.ql-font span[data-value='poppins']::before {
  font-family: 'Poppins';
}

.ql-font-serif,
.ql-font span[data-value='serif']::before {
  font-family: sans-serif;
}
.ql-font-monospace,
.ql-font span[data-value='monospace']::before {
  font-family: monospace;
}

.ql-font-ui-serif,
.ql-font span[data-value='ui-serif']::before {
  font-family: 'ui-serif';
}
.ql-font-ui-monospace,
.ql-font span[data-value='ui-monospace']::before {
  font-family: 'ui-monospace';
}

/* Set content for sizes */
.ql-size-extra-small,
.ql-size span[data-value='extra-small']::before {
  font-size: 13px !important;
}
.ql-size-small,
.ql-size span[data-value='small']::before {
  font-size: 14px !important;
}
.ql-size-medium,
.ql-size span[data-value='medium']::before {
  font-size: 18px !important;
}
.ql-size-large,
.ql-size span[data-value='large']::before {
  font-size: 25px !important;
}
.ql-size-extra-large,
.ql-size span[data-value='extra-large']::before {
  font-size: 30px !important;
}

/* Set content for colors */
.ql-color-red,
.ql-color span[data-value='red']::before {
  color: red !important;
}
.ql-color-green,
.ql-color span[data-value='green']::before {
  color: green !important;
}
.ql-color-blue,
.ql-color span[data-value='blue']::before {
  color: blue !important;
}
.ql-color-orange,
.ql-color span[data-value='orange']::before {
  color: orange !important;
}
.ql-color-purple,
.ql-color span[data-value='purple']::before {
  color: purple !important;
}
.ql-color-black,
.ql-color span[data-value='black']::before {
  color: black !important;
}

/* Set content for size */
.ql-size {
  width: 170px !important;
}
.ql-size-small,
.ql-size span[data-value='small']::before {
  font-size: 14px !important;
}
.ql-size-medium,
.ql-size span[data-value='medium']::before {
  font-size: 18px !important;
}
.ql-size-large,
.ql-size span[data-value='large']::before {
  font-size: 20px !important;
}
.ql-size-extra-large,
.ql-size span[data-value='extra-large']::before {
  font-size: 25px !important;
}
