@import '@shared-styles/src/assets/scss/colors.scss';

.common_search_container {
  width: 100%;
  .search_container {
    align-items: center;
    display: flex;
    gap: 10px;
    border-radius: 22px;
    width: 100%;
    background-color: $component-background;
    padding: 10px 20px;
    border: 1px solid $light-border;
    height: 48px;
    p {
      white-space: nowrap;
    }

    .add-value-popup {
      position: fixed;
      width: 200px;
      padding: 10px;
      border-radius: 8px;
      border: 1px solid $table-border;
      background: $dark-bg-2;
      box-shadow: 0px 0px 10px $box-shadow;
    }

    .search_input-tag-wrapper {
      display: flex;
      align-items: flex-start;
      align-content: center;
      gap: 5px;
      flex-wrap: wrap;
      width: 100%;

      .tag {
        cursor: pointer;
        .tag-name {
          color: $dark-bg-2;
          font-weight: 500;
          font-size: 14px;
          text-align: center;
          align-items: center;
          width: fit-content;
          span {
            margin-right: 5px;
          }
          .common_select_container {
            .toggle_button_wrapper {
              background: $sub-text;
              max-width: 100px;
              padding: 0px 4px;
              min-height: 20px !important;
              border: $light-border;
              font-size: 14px;
            }
          }
          .input_tag_value {
            background: $sub-text;
            max-width: 100px;
            padding: 0;
            height: 16px;
            border: none;
            font-size: 14px;
          }
          input:focus {
            outline: none;
            border: none;
          }
        }
        padding: 2px 10px;
        gap: 10px;
        border-radius: 20px;
        background: $sub-text;
        display: flex;
        align-items: center;

        .input-field {
          background: none;
          border: none;
          color: $dark-bg-2;
          font-size: 14px;
          font-weight: 500;
          padding: 0;
          margin: 0;
          outline: none;
        }
        .closeIcon {
          cursor: pointer;
          width: 16px;
          height: 16px;
          path {
            fill: $dark-bg-2;
          }
        }
      }
    }

    .error-message{
      padding-bottom: 4px;
    }

    .search_input {
      width: 90%;
    }
  }
}
