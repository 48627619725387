@import '@shared-styles/src/assets/scss/colors.scss';

.billing_container {
  height: 100%;
  display: flex;
  width: 100%;
  gap: 10px;
  flex-direction: column;

  .billing_top_container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
    overflow: auto;

    .billing_search_container {
      width: 100%;
      border-radius: 20px;
      display: flex;
      align-items: center;
      gap: 20px;
      position: relative;
      z-index: 5;
    }

    .pagination-button {
      color: #fff;
      font-size: 14px;
      padding: 5px 15px;
      background: $dark-bg-2;
      border-radius: 50px;
      line-height: 1.43;
      letter-spacing: 0.01071em;
      text-transform: none;
      border-radius: 8px;
      text-align: center;
      box-sizing: border-box;
      min-width: 32px;
      height: 32px;
      padding: 0 6px;
      margin: 0 3px;
      &active {
        background: 'rose';
      }
    }
    .pagination-button.active {
      background: #f90182 !important;
    }
    .pagination-button.disabled {
      color: grey !important;
      cursor: default;
    }
  }

  .billing_filter {
    display: flex;
    padding: 15px;
    justify-content: start;
    align-items: flex-start;
    gap: 25px;
    border-radius: 20px;
    background: $dark-bg-2;
    border: 1px solid $light-border;

    @media (max-width: 768px) {
      flex-direction: column;

      .dual-date-container {
        max-width: 100%;
      }
    }

    .common_multi_select_container {
      width: 100%;
      max-width: 350px;

      .MuiInputBase-root {
        padding: 1px 2px 2px 2px !important;
        min-height: 40px;
        grid-template-columns: max-content min-content 1fr min-content;
        max-height: 100px;
        overflow: auto;
        display: grid;

        .MuiAutocomplete-endAdornment {
          position: sticky;
          display: flex;
          align-items: center;
          gap: 5px;
          grid-column: -2/-1;
          grid-row: 1/-1;
        }

        .MuiButtonBase-root {
          grid-column: 1/2;
          width: fit-content;
        }

        input {
          padding: 4px;
          width: 100%;
          grid-column: span 2;
        }

        span.MuiAutocomplete-tag {
          ~input {
            grid-column: span 1;
          }
        }

        &.Mui-focused {
          grid-template-columns: 1fr min-content;

          input {
            grid-column: 1/2;
          }
        }
      }
    }
  }
}