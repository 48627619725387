@import '@shared-styles/src/assets/scss/colors.scss';

.h-full {
  .h-full {
    .Common_Table {
      .table {
        .table-body {
          min-height: 250px;
        }
      }
    }
  }
}
