@import '@shared-styles/src/assets/scss/colors.scss';

.invites_container {
  height: 100%;
  // padding: 10px;
  gap: 5px;
  border-radius: 10px;
  // border: 1px solid $background1;
  display: grid;
  grid-template-rows: 1fr;

  .tabs-list-container {
    display: flex;
    gap: 10px;
    height: 100%;
    overflow: auto;
  }
  .invites {
    position: relative;
    height: 100%;
    width: 100%;
    gap: 10px;
    display: grid;
    grid-template-rows: 1fr min-content;
  }

  div {
    &:empty {
      display: none;
    }
  }

  .MuiTabs-root {
    .MuiButtonBase-root {
      color: $sub-text;

      &.Mui-selected {
        color: $white;
      }
    }
  }

  .invites-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 10px;

    .invite-card {
      padding: 10px;
      border-radius: 10px;
      border: 1px solid $background1;
      display: grid;
      height: fit-content;
      gap: 10px;
      background-color: $dark-bg-2;

      &.rejected-card {
        background-color: transparent;
      }

      .invite-head {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4 {
          font-weight: 500;
        }

        span {
          color: $sub-text;
          font-size: 12px;
        }
      }

      .invite-body {
        color: $sub-text;
        font-size: 14px;

        span {
          margin: 6px 0;
          display: flex;
          border-bottom: 1px dashed $sub-text;
          width: fit-content;
          cursor: pointer;
        }
      }

      .invite-footer {
        display: flex;
        gap: 10px;

        button {
          border-radius: 8px;
          width: 100%;
          padding: 8px 20px;
          outline: none;
          border: 1px solid $background1;
          font-weight: 500;
          font-family: 'work-sans';
          cursor: pointer;

          &.accept-button {
            background-color: $phoenix-background;
            color: $success;
          }

          &.reject-button {
            color: $white;
            background-color: $dark-bg-2;
          }
        }
      }
    }
  }
}

.invites-popover {
  pointer-events: none;

  .MuiPaper-root {
    background-color: $dark-bg-2;
    background-image: none;
    box-shadow: -6px 10px 14px rgba(0, 0, 0, 0.35);
    border-radius: 10px;
    border: 1px solid $background1;
    margin-top: 5px;

    .invite-permissions {
      padding: 10px 18px 12px;
      font-size: 14px;
      display: grid;
      height: fit-content;
      gap: 8px;

      p {
        &::before {
          content: '';
          width: 7px;
          height: 7px;
          display: inline-block;
          margin-right: 10px;
          border-radius: 50%;
          background-color: $sub-text;
        }
      }
    }
  }
}
