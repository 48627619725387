@import '../../../assets/scss/colors.scss';

.button_field_wrapper {
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  background-color: $phoenix-background;
  color: $white;
  border: none;
  font-family: 'work-sans';
  font-weight: 500;
  font-size: 14px;

  .spinner {
    height: 20px !important;
    width: 20px !important;
  }
}

.button_phoenix {
  border-radius: 8px;
  color: $white;
  background-image: $phoenix-gradient;
  border: none;
  padding: 14px 10px;
  font-size: 14px;
  margin-top: 20px;
  transition: all 0.25s ease;

  &:hover {
    background-image: $phoenix-gradient-v2;
    opacity: 0.8;
  }
}

.button_secondary {
  display: flex;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 10px;
  border: 1px solid $border;
  background: $dark-bg-2;
  box-shadow: -2px 4px 20px 0px rgba(0, 0, 0, 0.1);

  &:hover {
    opacity: 0.8;
  }
}

.button_phoenix_secondary {
  display: flex;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 10px;
  border: 1px solid $border;
  background: $dark-bg-2 !important;
  box-shadow: -2px 4px 20px 0px rgba(0, 0, 0, 0.1);

  &:hover {
    background: $white !important;
    color: $dark-bg-2 !important;
  }
}

.button-disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.6;
}