.custom-tooltip.custom-tooltip.custom-tooltip {
  font-size: 0.6875rem;
  max-width: 300px;
  margin: 2px;
  word-wrap: break-word;
  font-weight: 500;
  color: #a3aed0;
  background-color: #141e2d;
  padding: 10px;
  border: 1px solid #b0bce133;
  border-radius: 7px;
}
