@import '@shared-styles/src/assets/scss/colors.scss';

.nestedRow {
  position: relative;

  .transaction_container {
    height: 100%;
    display: flex;
    width: 100%;
    gap: 10px;
    padding-top: 10px;

    .flex-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .transactions_table_container {
      height: 88%;
      border-radius: 10px;
      background: $dark-bg-2;
      border: 1px solid rgba(163, 174, 208, 0.2);
      overflow: auto;

      .transactions_table {
        overflow: auto;
        border-radius: 10px;

        .table_header {
          .MuiTableRow-head {
            .MuiTableCell-stickyHeader {
              background-color: $dark-bg !important;
            }
          }

          .flex-row {
            display: flex;
            align-items: center;
          }

          .table_header_cell {
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
            height: 48px;
            padding: 0px 12px;
            border-bottom: 1px solid rgba(163, 174, 208, 0.1);
            color: $sub-text;

            .header_text {
              color: $sub-text;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              white-space: nowrap;
            }
          }
        }

        .table_body {
          background-color: $nested-table-bg;

          .table_row {
            .table_cell {
              height: 64px;
              padding: 0px 12px;
              white-space: nowrap;
              color: $white;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              border-bottom: 1px solid rgba(163, 174, 208, 0.1);

              .reason-column {
                max-width: 400px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                white-space: break-spaces;
                word-break: break-word;
              }
            }

            .link_btn {
              cursor: pointer;
              color: $sec-ui;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;

              &:hover {
                text-decoration-line: underline;
              }
            }
          }
        }
      }
    }
  }
}
