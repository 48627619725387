@import '@shared-styles/src/assets/scss/colors.scss';

.action_filter_container {
  .action_filter_columns {
    padding: 10px 0;
    border-bottom: 1px solid $border-bottom;
    color: $sub-text;
  }
}
.error-message {
  color: $error-red;
  font-size: 14px;
  margin-top: 5px;
  display: flex;
  font-weight: 500;
}
