@import '@shared-styles/src/assets/scss/colors.scss';

.rention-report {
  display: grid;
  height: 100%;
  grid-template-rows: min-content 1fr;
  gap: 20px;
  align-self: stretch;

  .retention-filters {
    display: flex;
    padding: 15px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    border-radius: 20px;
    width: 100%;
    background: $component-background;
    border: 1px solid $light-border;

    .filter-selection-form {
      display: flex;
      flex-direction: row;
      gap: 15px;

      .block-product-varient {
        opacity: 0.5;
      }

    }
  }

  .rention-first-table {
    height: 100%;
    display: grid;
    overflow: auto;
    grid-template-rows: min-content 1fr;
    gap: 10px;
    align-self: stretch;

    .heading {
      color: $white;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: -0.68px;
    }

    .retention {
      width: 100%;
      height: 100%;
      overflow: auto;
      gap: 20px;

      .Common_Table {
        // max-height: 400px;
        overflow: auto;

        .MuiTable-root {
          min-height: 100px;
        }

        .table_row {
          &.salvage {

            td,
            th {
              background-color: hsl(from var(--component-background) h 30 l);
            }
          }
        }

        &.rentention_page_table {
          .table_body {
            * {
              font-family: monospace;
            }
          }

          .table_header,
          .table_body {
            * {
              text-align: end;
            }
          }
        }
      }

      .table_cell {
        border-left: 1px solid rgba(163, 174, 208, 0.2);
      }

      .percentage_column {
        position: relative;
        isolation: isolate;

        .percent-width {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          z-index: -1;

          &.attempted {
            background-color: #f363371a;
          }

          &.approved {
            background-color: #05cd991a;
          }

          &.decline {
            background-color: #e840401a;
          }

          &.disputecancelledbeforebilling {
            background-color: #a3aed01a;
          }

          &.supportcancelledbeforebilling {
            background-color: #a3aed01a;
          }
        }

        .table_value {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}