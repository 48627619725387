@import '@shared-styles/src/assets/scss/colors.scss';

.common-multi-select-input {
  display: flex;
  flex-direction: column;
  gap: 6px;
  min-height: 1.4375em;

  .label {
    color: $white;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.28px;
  }

  .inputWrapper {
    background: $component-background;
    border-radius: 4px;
    padding: 3px 6px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;

    @if $dark-theme {
      border: 1px solid $border;
    }

    .text_field_input_multiInput {
      background: $component-background;
      color: $white;
      box-sizing: border-box;
      padding: 6px;
      width: 10px;
      overflow: auto;
      flex-grow: 1;
      border: 0;
      margin: 0;
      outline: 0;
      height: 36px;

      &::placeholder {
        color: $placeholder;
        opacity: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family:
          work-sans,
          -apple-system,
          BlinkMacSystemFont,
          'Segoe UI',
          Roboto,
          sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: -0.28px;
      }
    }

    .MuiChip-label {
      font-family: 'work-sans';
    }
  }

  .custom_styled_tag {
    background-color: $placeholder !important;
    height: 32px;
    padding: 0 0.5rem;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 13px;
      color: $white;
    }

    path {
      fill: $sub-text;
    }

    .search_content_tag {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .delete_tag {
      cursor: pointer;
      background-color: $sub-text;
      border-radius: 50px;
      padding: 0 6.5px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 5px;

      .input_del_icon {
        width: 8px;

        path {
          fill: currentColor;
        }
      }
    }
  }

  .addButton {
    height: 25px;
    line-height: 25px;
    width: 25px;
    border-radius: 50%;
    color: $white;
    text-align: center;
    cursor: pointer;
    margin: auto;
    font-weight: 400;
    font-size: 1rem;
  }

  .error_message {
    color: $error-red;
    font-size: 12px;
  }
}

.store_multiLevelSelect {
  &.focused {
    .common-multi-select-input {
      .custom_styled_tag {
        .search_content_tag {
          span {
            width: auto;
            max-width: none;
          }
        }
      }
    }
  }
  .common-multi-select-input {
    .custom_styled_tag {
      .search_content_tag {
        span {
          display: inline-block;
          width: fit-content;
          max-width: 90px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          vertical-align: middle;
        }
      }
    }
  }
}
