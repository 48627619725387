@import '@shared-styles/src/assets/scss/colors.scss';

.subscription_events_wrapper {
  height: 100%;
  display: flex;
  width: 100%;
  gap: 10px;

    @media (max-width: 535px) {
    flex-direction: column-reverse;
  }

  .subscription_events {
    height: 100%;
    width: calc(100% - 48px);
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
    overflow: auto;

    @media (max-width: 535px) {
      width: 100%;
    }
    .subscription_events_search_container {
      width: 100%;
      border-radius: 20px;
      display: flex;
      align-items: center;
      gap: 20px;
      position: relative;
      z-index: 5;
    }
    #Reason {
      max-width: 400px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
