@import '@shared-styles/src/assets/scss/colors.scss';

.login-verification-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  padding: 20px;
  position: relative;

  .title-login-container {
    display: flex;
    align-items: center;
    gap: 10px;

    .title-text {
      font-size: 30px;
    }
  }
  .login-verification-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 400px;
    width: 100%;
    gap: 15px;
    align-items: flex-start;
    position: relative;
    z-index: 1;
    margin: auto;

    .form-text {
      font-size: 18px;
    }

    .otp-container {
      display: flex;
      justify-content: space-between;
      gap: 10px;
    }
    .otp-input-styles {
      width: 57.5px !important;
      aspect-ratio: 1;
      border-radius: 8px;
      border: none;
      outline: none;
      background: $component-background;
      color: $white;
      caret-color: $white;
      font-family: 'work-sans';
      font-size: 24px;
      font-weight: 500;
      border: 1px solid $border;
      @media (max-width: 560px) {
        width: 100% !important;
      }
    }
    .verification-tip {
      color: $sub-text;
      font-size: 12px;
    }
    .login-verify-submit {
      width: 100%;
    }
    .login-verify-footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 20px;

      .back-to-login {
        display: flex;
        align-items: center;
        font-size: 14px;
        cursor: pointer;
        gap: 5px;
      }
      .resend-otp {
        font-size: 14px;
        display: flex;
        .resend-otp-link {
          color: $yellow;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      @media (max-width: 560px) {
        .back-to-login {
          font-size: 12px;
          svg {
            width: 20px;
            height: 20px;
          }
        }
        .resend-otp {
          font-size: 12px;
        }
      }
    }
  }

  .animated-circle {
    width: 484px;
    height: 484px;
    border-radius: 50%;
    position: absolute;
    filter: blur(100px);
  }
  .right-circle {
    top: -124px;
    right: -100px;
    opacity: 0.4;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(103, 80, 164, 0.98) 0%,
      $box-shadow 100%
    );
    background-blend-mode: screen;
  }
  .top-circle {
    top: -215px;
    left: -150px;
    opacity: 0.4;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(255, 216, 175, 0.98) 0%,
      $box-shadow 100%
    );
    background-blend-mode: screen;
  }
  .bottom-circle {
    bottom: -350px;
    left: 190px;
    opacity: 0.4;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(200, 114, 202, 0.98) 0%,
      $box-shadow 100%
    );
    background-blend-mode: screen;
  }
}
