@import '@shared-styles/src/assets/scss/colors.scss';
.membership-badge {
  padding: 4px 10px;
  border-radius: 8px;
  background-color: $background1;
  font-size: 12px;
}
.product-list-container {
  border: 1px solid $border;
}
.products-container {
  border-bottom: 1px solid $border;
  &:last-child {
    border-bottom: none;
  }
  .font-normal {
    font-size: 14px;
  }
}
