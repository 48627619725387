@import '@shared-styles/src/assets/scss/colors.scss';
.cascade_container {
  width: calc(100% - 40px);
  .top_bar {
    display: flex;
    justify-content: space-between;
    .select_website {
      width: 274px;
    }

    .update_button {
      height: 40px;
      width: 100px;
      border-radius: 10px;
      background-color: $dark-bg-2;
      align-self: center;
    }
  }
  .last_update {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .last_update {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.42px;
    letter-spacing: -0.02em;
    text-align: left;
    color: $sub-text;
  }
  .cascade {
    display: flex;
    width: 100%;
    gap: 10px;
    .risk_wrapper_container {
      position: relative;
      height: 100%;
      width: 100%;
      padding-right: 5px;
      gap: 10px;
      display: flex;
      flex-direction: column;
      overflow: auto;
      .risk-container {
        position: relative;
        padding-right: 5px;
        gap: 10px;
        display: flex;
        flex-direction: column;
        overflow: auto;

        .risk_management {
          width: 100%;
          gap: 20px;
          display: grid;
          grid-template-rows: 1fr min-content;
          position: relative;
          .Common_Table {
            .MuiTable-root {
              min-height: 250px;
            }

            .risk_management_table {
              border-radius: 10px;
              .table_header {
                .MuiTableRow-head {
                  .table_header_cell {
                    .MuiTableCell-stickyHeader {
                      background-color: rgba(163, 174, 208, 0.2) !important;
                    }
                  }
                  .MuiTableRow-root {
                    height: 48px;
                    padding: 0px 12px;
                    border-bottom: 1px solid rgba(163, 174, 208, 0.1);
                    background-color: $dark-bg-2 !important;

                    &.header_text {
                      color: $sub-text;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 600;
                      white-space: nowrap;
                    }
                  }
                }

                .flex-row {
                  display: flex;
                  align-items: center;
                }
              }

              .table_body {
                .table_row {
                  .table_cell {
                    height: 64px;
                    padding: 0px 12px;
                    white-space: nowrap;
                    color: $sub-text;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    border-bottom: 1px solid rgba(163, 174, 208, 0.1);

                    .input_edit {
                      width: 200px;
                      height: 42px;
                      padding: 12px 16px 12px 16px;
                      border-radius: 10px;
                      background-color: #a3aed033;
                    }
                    input:focus {
                      outline: none;
                      border: none;
                    }
                  }

                  .link_btn {
                    cursor: pointer;
                    color: $sec-ui;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;

                    &:hover {
                      text-decoration-line: underline;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .actions {
    .action_logs {
      padding: 5px;
    }
    .action-container {
      border: 1px solid $placeholder;
      background-color: $dark-bg-2 !important;
      margin: 10px 0;
      border-radius: 10px;
    }
  }
}

.popup-section-preview-table {
  width: 100% !important;
  .popup-table {
    padding: 5px;
    background-color: $dark-bg;
    border-radius: 10px;
  }
}

.popup_section_content_error {
  width: 100% !important;
  .content-error {
    display: flex;
    gap: 20px;
    flex-direction: column;
    .error {
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
    }
    .content {
      display: flex;
      flex-direction: column;
      gap: 5px;
      color: var(--sub-text, #a3aed0);
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.02em;
      margin-left: 10px;
    }
  }
}
