.pre-upsell {
    .h-full {
      .h-full {
        .Common_Table {
          .table {
            .table-body {
              min-height: 250px;
            }
          }
        }
      }
    }
  }
  