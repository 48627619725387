@import '@shared-styles/src/assets/scss/colors.scss';

.tabsContainer {
  border-bottom: 1px solid $border;
  gap: 5px;
  .MuiTabs-flexContainer {
    gap: 10px;
    padding: 2px 1px 0;
  }
  > .MuiButtonBase-root {
    margin: 2px 1px 0;
    &.Mui-disabled {
      opacity: 0.3;
    }
  }
  .MuiButtonBase-root {
    color: $white;
    font-size: 16px;
    border-radius: 10px 10px 0 0;
    padding: 10px 24px;
    background-color: $table-border;
    display: inline-flex !important;
    &.Mui-selected {
      color: $prim-ui;
      font-weight: 600;
    }
    &:hover {
      outline: 1px solid $border;
      background-color: $component-background;
    }
  }
  .MuiTabs-indicator {
    background-color: $prim-ui;
  }
}
