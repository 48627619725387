@import '@shared-styles/src/assets/scss/colors.scss';

.text_field_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;

  .common_input_label {
    font-size: 12px;
    color: $white;
  }

  .text_field_input {
    width: 100%;
    border: none;
    background: $component-background;
    color: $white;
    border-radius: 5px;

    @if $dark-theme {
      border: 1px solid $border;
    }

    ::placeholder {
      color: $placeholder;
      opacity: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family:
        work-sans,
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        Roboto,
        sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: -0.28px;
    }

    &:focus-visible {
      outline: none;
    }
    textarea {
      &:focus {
        box-shadow: none !important;
      }
    }

    .MuiInputBase-root {
      height: 100%;
    }

    &.size_md {
      .MuiInputBase-input {
        padding: 8px 12px;
        caret-color: $white;
        color: $white;
      }
    }
  }

  .text_field_error {
    border: 1px solid $error-red !important;
  }

  .error_message {
    color: $error-red;
    font-size: 12px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
}
