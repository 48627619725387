@import '@shared-styles/src/assets/scss/colors.scss';

.pagination_container {
  container: paginationContainer / inline-size;
}

.common_pagination_container {
  justify-content: space-between;
  width: 100%;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  font-size: 14px;

  .common_pagination_ul {
    padding: 0;
    margin: 0;
    display: flex;
    list-style: none;
    gap: 5px;
    justify-content: center;

    .common_pagination_button {
      padding: 8px;
      background-color: $dark-bg-2;
      color: $sub-text;
      border: none;
      border-radius: 5px;
      min-width: 32px;
      min-height: 32px;
      cursor: pointer;
      font-weight: 500;
      font-family: 'work-sans';

      &:hover {
        color: $dark-bg-2;
        background-color: $sub-text;
      }

      &.disabled {
        background-color: $dark-bg-2;
        color: $sub-text;
        pointer-events: none;
      }
    }

    .common_pagination_selected {
      background-color: $prim-ui;
      color: $white;
    }
  }

  .row_content_decider {
    display: flex;
    align-items: center;
    gap: 10px;
    .rows_page {
      color: $sub-text;
    }
    .common_select_container {
      .toggle_button_wrapper {
        min-width: 75px;
        padding: 6px 14px;
      }
    }
  }
  .pagination-details {
    text-align: center;
  }
  .pagination-container {
    .MuiPagination-ul {
      justify-content: end;
      li {
        .MuiButtonBase-root {
          background-color: $dark-bg-2;
          border-radius: 8px;
          color: $white;
          &.Mui-selected {
            background-color: $prim-ui;
            @if $dark-theme {
              color: $black;
            }
          }
          &.MuiPaginationItem-previousNext {
            svg {
              display: none;
            }
            &[aria-label='Go to previous page'] {
              &::before {
                content: 'Prev';
              }
            }
            &[aria-label='Go to next page'] {
              &::before {
                content: 'Next';
              }
            }
          }
        }
        .MuiPaginationItem-ellipsis {
          color: $white;
        }
      }
    }
  }
}

@container paginationContainer (max-width: 1080px) {
  .common_pagination_container {
    grid-template-columns: 1fr max-content;
    gap: 5px;
    .pagination-details {
      text-align: right;
    }
    .pagination-container {
      grid-column: 1/-1;
      .MuiPagination-ul {
        gap: 5px 0;
      }
    }
  }
}
